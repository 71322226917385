import { useUser } from '@clerk/clerk-react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import qs from 'qs';
import { useCallback, useEffect, useMemo } from 'react';

import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  FIVE_MINUTES,
  queryKeys,
} from 'src/utils';

import { useFetch } from './useFetch';
import { useGetUserPrivileges } from './useGetUserPrivileges';

import type { PaginatedData, Privilege } from 'src/types';

export const useGetPaginatedData = <DataRaw, DataCleaned = DataRaw>({
  endpointUrl,
  queryKeyBase,
  page = DEFAULT_PAGE,
  pageSize = DEFAULT_PAGE_SIZE,
  filters,
  dataFormatter,
  requiredPrivileges,
  autoFetchNextPage = true,
  enabled = true,
}: {
  endpointUrl: string;
  queryKeyBase: string | unknown[] | readonly unknown[];
  page?: number;
  pageSize?: number;
  // TODO: figure out filters
  filters?: Record<string, string | null>;
  dataFormatter?: (data: DataRaw[]) => DataCleaned[];
  requiredPrivileges?: Privilege[];
  autoFetchNextPage?: boolean;
  enabled?: boolean;
}) => {
  const { fetchApi } = useFetch();
  const { isSignedIn } = useUser();
  const queryClient = useQueryClient();
  const { privileges } = useGetUserPrivileges();

  const hasAccess = requiredPrivileges?.every(
    (privilege) => privileges?.[privilege],
  );

  const queryKeyData = useMemo(
    () => ({
      page,
      pageSize,
      filters,
    }),
    [page, pageSize, filters],
  );

  const queryKey = queryKeys.paginatedData.page(queryKeyBase, queryKeyData);

  const getPaginatedData = useCallback(
    async <DataRaw, DataCleaned = DataRaw>({
      endpointUrl,
      page,
      pageSize,
      filters = {},
      dataFormatter,
    }: {
      endpointUrl: string;
      page: number;
      pageSize: number;
      filters?: Record<string, string | null>;
      dataFormatter?: (data: DataRaw[]) => DataCleaned[];
    }) => {
      const queryParamsString = qs.stringify({
        page: page,
        pageSize: pageSize,
        ...filters,
      });

      const { data } = await fetchApi.get<PaginatedData<DataRaw>>(
        `${endpointUrl}?${queryParamsString}`,
      );

      if (dataFormatter) {
        return {
          ...data,
          results: dataFormatter(data.results),
        } as PaginatedData<DataCleaned>;
      } else {
        return { ...data, results: data.results as unknown as DataCleaned[] };
      }
    },
    [fetchApi],
  );
  const queryResult = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps -- TODO: need to clean this up
    queryKey,
    queryFn: () =>
      getPaginatedData<DataRaw, DataCleaned>({
        endpointUrl,
        page,
        pageSize,
        filters,
        dataFormatter,
      }),
    staleTime: FIVE_MINUTES,
    cacheTime: FIVE_MINUTES,
    enabled:
      !!isSignedIn &&
      !!hasAccess &&
      !!page &&
      !!pageSize &&
      !!endpointUrl &&
      enabled,
  });

  useEffect(() => {
    // Prefetch next page data if current does not exist
    const nextPageQueryKey = queryKeys.paginatedData.page(queryKeyBase, {
      ...queryKeyData,
      page: page + 1,
    });
    const nextPageData =
      queryClient.getQueryData<PaginatedData<DataCleaned>>(nextPageQueryKey);
    const nextPageStatus = queryClient.getQueryState(nextPageQueryKey);
    if (
      autoFetchNextPage &&
      !nextPageData &&
      nextPageStatus?.fetchStatus !== 'fetching' &&
      page < (queryResult.data?.totalPages ?? 0)
    ) {
      void queryClient.prefetchQuery(
        queryKeys.paginatedData.page(queryKeyBase, {
          ...queryKeyData,
          page: page + 1,
        }),
        () =>
          getPaginatedData<DataRaw, DataCleaned>({
            endpointUrl,
            page: page + 1,
            pageSize,
            filters,
            dataFormatter,
          }),
      );
    }

    // Prefetch previous page data if current does not exist
    const previousPageQueryKey = queryKeys.paginatedData.page(queryKeyBase, {
      ...queryKeyData,
      page: page - 1,
    });
    const previousPageData =
      queryClient.getQueryData<PaginatedData<DataCleaned>>(
        previousPageQueryKey,
      );
    const previousPageStatus = queryClient.getQueryState(previousPageQueryKey);
    if (
      autoFetchNextPage &&
      !previousPageData &&
      previousPageStatus?.fetchStatus !== 'fetching' &&
      page > 1
    ) {
      void queryClient.prefetchQuery(
        queryKeys.paginatedData.page(queryKeyBase, {
          ...queryKeyData,
          page: page - 1,
        }),
        () =>
          getPaginatedData<DataRaw, DataCleaned>({
            endpointUrl,
            page: page + 1,
            pageSize,
            filters,
            dataFormatter,
          }),
      );
    }
  }, [
    autoFetchNextPage,
    dataFormatter,
    endpointUrl,
    filters,
    getPaginatedData,
    page,
    pageSize,
    queryClient,
    queryKeyBase,
    queryKeyData,
    queryResult.data?.totalPages,
  ]);

  return { ...queryResult, queryKey };
};
