import { useUser } from '@clerk/clerk-react';
import { useQuery } from '@tanstack/react-query';
import { lowerCase } from 'lodash';
import { generatePath } from 'react-router-dom';

import { useGetUserPrivileges } from 'src/hooks';
import { useFetch } from 'src/hooks/useFetch';
import { SERVICE_BENJI_CARD_URLS, queryKeys } from 'src/utils';

import type { GroupDetailsCleaned, GroupDetailsRaw } from 'src/types/groups';

export const useGetGroupDetails = ({ id }: { id?: string }) => {
  const { fetchApi } = useFetch();
  const { privileges } = useGetUserPrivileges();
  const { isSignedIn } = useUser();

  const getGroupDetails = async ({ id }: { id: string }) => {
    const { data: groupDetails } = await fetchApi.get<GroupDetailsRaw>(
      `${generatePath(SERVICE_BENJI_CARD_URLS.GROUP_GET_DETAILS, {
        groupId: id,
      })}`,
    );

    const status = lowerCase(groupDetails.status);

    return {
      id: groupDetails.id,
      name: groupDetails.name,
      status,
      balanceAllocatedCents: groupDetails.balance_allocated_cents,
      balanceAvailableCents: groupDetails.balance_available_cents,
      balanceCurrentCents: groupDetails.balance_current_cents,
      balanceUnallocatedCents: groupDetails.balance_unallocated_cents,
      balancePercentAllocated: groupDetails.balance_allocated_percent,
    } as GroupDetailsCleaned;
  };

  return useQuery({
    queryKey: queryKeys.groups.details({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- will only get called when id is defined due to enabled check
      id: id!,
    }),
    queryFn: () =>
      getGroupDetails({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- will only get called when id is defined due to enabled check
        id: id!,
      }),
    enabled: !!isSignedIn && !!privileges?.cards && !!id,
  });
};
