import { css } from '@emotion/react';
import { Box, Button, Card, Chip, Typography } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { get } from 'lodash';
import { useRef, useState } from 'react';

import {
  AsynchronouslyLoadedSection,
  InfoPopover,
  PaginatedTableWrapper,
  StatusChip,
  Table,
  TableError,
  TableLoader,
  TableMenu,
  TableNoResults,
  TablePagination,
} from 'src/components';
import { CreateCardholderIcon } from 'src/components/Icon';
import { SearchBar } from 'src/components/PaginatedTableWrapper/SearchBar';
import { FilterType, TableFilters } from 'src/components/TableFilters';
import { TrackType, useToast, useTrack } from 'src/context';
import {
  useAppRoutes,
  useGetFeatures,
  useGetUserDetails,
  useGetUserPrivileges,
} from 'src/hooks';
import {
  DownloadEntity,
  useDownloadTableCsv,
} from 'src/hooks/useDownloadTableCsv';
import { Privilege } from 'src/types';
import { AccountStatus } from 'src/types/account';
import {
  CardholderStatus,
  type CardholderCleaned,
  type CardholderRaw,
} from 'src/types/cardholders';
import {
  SERVICE_BENJI_CARD_URLS,
  formatDateToMST,
  formatISODate,
  queryKeys,
} from 'src/utils';

import { cleanCardholders } from './utils';

import {
  CardholderDetailsModal,
  useCardholderDetailsModal,
} from '../../components/modals/CardholderDetailsModal';

import type { PaginatedTableFilter } from 'src/components/PaginatedTableWrapper';
import type { TableColumn } from 'src/components/Table';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY ?? '');

export const CardholdersPage = () => {
  const { navigate } = useAppRoutes();
  const { track } = useTrack();
  const { setToast } = useToast();

  const cardRef = useRef<HTMLDivElement>(null);
  const { privileges } = useGetUserPrivileges();
  const { data: userData, isLoading: isUserLoading } = useGetUserDetails();

  const { isLoading: areUserPrivilegesLoading } = useGetUserPrivileges();

  const isPageLoading = areUserPrivilegesLoading || isUserLoading;

  const { features } = useGetFeatures();

  const columns: ({ width: string } & TableColumn<CardholderCleaned>)[] = [
    {
      label: 'Name',
      renderCell: ({ name }) => name.full,
      width: 'minmax(150px, 250px)',
    },
    {
      label: 'DOB',
      width: '130px',
      renderCell: ({ dob }) => (
        <span className="highlight-block">{formatISODate(dob ?? '')}</span>
      ),
    },
    {
      label: 'Status',
      width: '120px',
      renderCell: ({ status }) => {
        return (
          <Box
            css={css`
              display: flex;
            `}
          >
            <StatusChip status={status} type="cardholder" />
          </Box>
        );
      },
    },
    ...(features?.groups
      ? [
          {
            label: 'Group',
            renderCell: ({ group }) => group?.name,
            width: 'minmax(150px, 250px)',
          } as { width: string } & TableColumn<CardholderCleaned>,
        ]
      : []),
    {
      label: 'Tags',
      width: 'minmax(150px, 2fr)',
      renderCell: ({ aggregateRemainingSpendLimitCents, isActive }) => (
        <Box
          css={css`
            display: flex;
            align-items: center;
            align-content: center;
          `}
        >
          {(aggregateRemainingSpendLimitCents ?? 0) > 0 && isActive && (
            <Chip
              label={
                <Box
                  css={css`
                    display: flex;
                    gap: 4px;
                    cursor: default;
                    align-items: center;
                  `}
                >
                  Unspent Limts
                  <InfoPopover
                    contents="Has active cards with spend limits that have not been used."
                    name="Unpent Limits"
                    css={css`
                      margin-bottom: 0;
                    `}
                  />
                </Box>
              }
              size="small"
              onClick={(e) => e.stopPropagation()}
            />
          )}
        </Box>
      ),
    },
    {
      // Spacer column to fill the remaining space
      label: '',
      renderCell: () => null,
      width: '1fr',
    },
  ];

  const { openModal: openCardholderDetailsModal } = useCardholderDetailsModal();

  const [filters, setFilters] = useState<PaginatedTableFilter>({});
  const cleanedFilters = {
    ...filters,
    // Only send hasUnspentLimits if it is true.
    ...(filters.hasUnspentLimits
      ? {
          hasUnspentLimits: filters.hasUnspentLimits,
        }
      : { hasUnspentLimits: undefined }),
  };

  const { mutate: download, isLoading: isDownloading } = useDownloadTableCsv();

  return (
    <Elements stripe={stripePromise}>
      <Box
        css={css`
          max-height: calc(100vh - 100px);
          height: 100%;
          width: 100%;
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
        `}
      >
        <Card
          component="div"
          css={css`
            display: flex;
            width: 100%;
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            gap: 8px;
            flex: 1 1 auto;
          `}
          ref={cardRef}
        >
          <PaginatedTableWrapper<CardholderRaw, CardholderCleaned>
            endpointUrl={SERVICE_BENJI_CARD_URLS.CARDHOLDERS_GET}
            queryKeyBase={queryKeys.cardholders._baseKey}
            dataFormatter={cleanCardholders}
            requiredPrivileges={[Privilege.cards]}
            filters={cleanedFilters}
          >
            {({
              queryResult: {
                isLoading,
                isError,
                data: { invalidFiltersWarningMessages } = {},
              },
              pagination,
              paginationData,
              resultCount,
              pageCount,
              searchProps,
            }) => {
              return (
                <>
                  <Box
                    css={css`
                      width: 100%;
                      display: flex;
                      flex-wrap: wrap;
                      align-items: center;
                      align-content: center;
                      gap: 8px 16px;
                      padding: 16px 16px 0;
                    `}
                  >
                    <Typography
                      variant="h2"
                      css={css`
                        flex-grow: 999;
                      `}
                    >
                      Cardholders
                    </Typography>

                    <Box
                      css={css`
                        display: flex;
                        flex-wrap: wrap-reverse;
                        gap: 16px;
                        flex-grow: 1;
                        justify-content: flex-end;
                      `}
                    >
                      <SearchBar {...searchProps} isLoading={!!isLoading} />
                      {privileges?.issue_card && (
                        <Button
                          variant="contained"
                          size="large"
                          onClick={() =>
                            navigate('/secure/cardholders/new-cardholder')
                          }
                          disabled={
                            isPageLoading ||
                            userData?.accountDetails.status !==
                              AccountStatus.ACTIVE
                          }
                          startIcon={<CreateCardholderIcon />}
                        >
                          Create Cardholder
                        </Button>
                      )}
                      <TableMenu
                        disabled={isPageLoading}
                        menuItems={[
                          {
                            label: 'Download CSV',
                            onClick: () => {
                              track({
                                label: 'Clicked Download Cardholder Table',
                                type: TrackType.action,
                                actionType: 'click',
                              });
                              const today = new Date();
                              const createdStartDate = get(
                                filters,
                                'createdOn.startDate',
                              );
                              const createdEndDate = get(
                                filters,
                                'createdOn.endDate',
                              );
                              const hasOnlyStartDateSet =
                                !!createdStartDate && !createdEndDate;
                              download(
                                {
                                  downloadEntity: DownloadEntity.CARDHOLDERS,
                                  filters: {
                                    ...cleanedFilters,
                                    ...(!!hasOnlyStartDateSet && {
                                      createdOn: {
                                        startDate: createdStartDate,
                                        // Default end date to today if only start date is set.
                                        endDate: formatDateToMST(
                                          today,
                                          'yyyy-MM-dd',
                                        ),
                                      },
                                    }),
                                    search: searchProps.searchValue,
                                  },
                                },
                                {
                                  onError: (error) => {
                                    const message = get(
                                      error,
                                      'message',
                                      'An error occurred while downloading the CSV. Try different filters or try again later.',
                                    );
                                    setToast({
                                      message,
                                      severity: 'error',
                                    });
                                  },
                                },
                              );
                            },
                            disabled: isDownloading,
                          },
                        ]}
                      />
                    </Box>
                  </Box>
                  <AsynchronouslyLoadedSection isLoading={isPageLoading}>
                    <Box
                      css={css`
                        width: 100%;
                        display: flex;
                        gap: 8px 16px;
                        flex-wrap: nowrap;
                        padding: 0 16px;
                      `}
                    >
                      <TableFilters
                        setFilters={setFilters}
                        priorityFilterCount={1}
                        filters={[
                          {
                            name: 'status',
                            label: 'Status',
                            type: FilterType.SELECT,
                            defaultValue: CardholderStatus.ACTIVE,
                            widthPx: 110,
                            options: [
                              { value: '', label: 'All' },
                              {
                                value: CardholderStatus.ACTIVE,
                                label: 'Active',
                              },
                              {
                                value: CardholderStatus.INACTIVE,
                                label: 'Inactive',
                              },
                            ],
                          },
                          {
                            name: 'createdOn',
                            label: 'Created On',
                            type: FilterType.DATE_RANGE,
                            defaultValue: {
                              startDate: null,
                              endDate: null,
                            },
                            widthPx: 110,
                          },
                          {
                            name: 'hasUnspentLimits',
                            label: 'Unspent Limits',
                            type: FilterType.SWITCH,
                            defaultValue: false,
                          },
                          {
                            name: 'groupId',
                            label: 'Viewing Single Group',
                            defaultValue: '',
                            type: FilterType.CHIP,
                          },
                        ]}
                      />
                    </Box>
                    <Table<CardholderCleaned>
                      data={paginationData}
                      pagination={pagination}
                      columns={columns}
                      isLoading={isLoading || isPageLoading}
                      onRowClick={({ stripeId }) => {
                        openCardholderDetailsModal(stripeId);
                      }}
                      columnWidths={columns.map(({ width }) => width).join(' ')}
                      css={css`
                        margin-top: 10px;
                        width: 100%;
                      `}
                    />
                    <TableLoader isLoading={isLoading} />
                    <TableError isLoading={isLoading} isError={isError} />
                    <TableNoResults
                      isLoading={isLoading}
                      resultCount={resultCount}
                      searchTerms={searchProps.searchValue}
                      noResultMessage={
                        invalidFiltersWarningMessages?.length
                          ? `${invalidFiltersWarningMessages
                              .map(({ message }) => message)
                              .join(', ')}.`
                          : `Please try a different search term. You can search by the cardholder${`'`}s name, email or phone number.`
                      }
                    />
                    <TablePagination
                      resultCount={resultCount}
                      pageCount={pageCount}
                      pagination={pagination}
                    />
                  </AsynchronouslyLoadedSection>
                </>
              );
            }}
          </PaginatedTableWrapper>
        </Card>
      </Box>
      <CardholderDetailsModal anchorEl={cardRef.current} />
    </Elements>
  );
};
