import { css } from '@emotion/react';
import {
  Box,
  Card,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  Autocomplete,
  Typography,
  CircularProgress,
  Alert,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { differenceInDays, isBefore } from 'date-fns';
import { debounce, startCase } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import {
  FancyCurrencyDisplay,
  PaginatedTableWrapper,
  StatusChip,
  Table,
  TableError,
  TableLoader,
  TableNoResults,
  TablePagination,
} from 'src/components';
import { DisputesIcon } from 'src/components/Icon';
import {
  DisputeDetailsModal,
  useDisputeDetailsModal,
} from 'src/components/modals/DisputeDetailsModal';
import { SearchBar } from 'src/components/PaginatedTableWrapper/SearchBar';
import { FilterType, TableFilters } from 'src/components/TableFilters';
import { TrackType, useTrack } from 'src/context';
import {
  useAppRoutes,
  useGetFeatures,
  useGetPaginatedData,
  useGetUserDetails,
  useGetUserPrivileges,
} from 'src/hooks';
import { useFetch } from 'src/hooks/useFetch';
import { COLOR_PALETTE } from 'src/theme';
import { Privilege } from 'src/types';
import { AccountStatus } from 'src/types/account';
import { STRIPE_TIME_LIMIT_TO_RAISE_DISPUTE_IN_DAYS } from 'src/utils/consts';

import { cleanDisputes } from './utils';

import { SERVICE_BENJI_CARD_URLS, formatISODate, queryKeys } from '../../utils';
import { cleanTransactions } from '../Transactions/utils';

import type { Column } from '@table-library/react-table-library/types/compact';
import type { PaginatedTableFilter } from 'src/components/PaginatedTableWrapper';
import type { TransactionCleaned } from 'src/types/authorizations-transactions';
import type { DisputeCleaned, DisputeRaw } from 'src/types/dispute';

const Disputes = () => {
  const { navigate } = useAppRoutes();

  const cardRef = useRef<HTMLDivElement>(null);
  const { features } = useGetFeatures();
  const { track, incrementMixpanelProfileData } = useTrack();
  const { openModal: openDisputeModal } = useDisputeDetailsModal();
  const { fetchApi } = useFetch();

  const { isLoading: areUserPrivilegesLoading, privileges } =
    useGetUserPrivileges();
  const { data: userData, isLoading: isUserLoading } = useGetUserDetails();

  const [openCreateDisputeDialog, setOpenCreateDisputeDialog] = useState(false);
  const [createDisputeTransaction, setCreateDisputeTransaction] = useState<
    TransactionCleaned | undefined
  >(undefined);
  const [disputeReason, setDisputeReason] = useState<string>();
  const [productType, setProductType] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix
  const [productDescription, setProductDescription] = useState<any>();
  const [originalTransaction, setOriginalTransaction] = useState<
    TransactionCleaned | undefined
  >();
  const [returnDescription, setReturnDescription] = useState<string>();
  const [explanation, setExplanation] = useState<string>();
  const [expectedDate, setExpectedDate] = useState<string>();
  const [receivedDate, setReceivedDate] = useState<string>();
  const [returnStatus, setReturnStatus] = useState<string>();
  const [returnDate, setReturnDate] = useState<string>();
  const [cancelDate, setCancelDate] = useState<string>();
  const [cancelReason, setCancelReason] = useState<string>();
  const [cancellationPolicyProvided, setCancellationPolicyProvided] =
    useState<boolean>(false);
  const queryClient = useQueryClient();

  const [daysSinceTransaction, setDaysSinceTransaction] = useState<number>(0);
  const [isDisputable, setIsDisputable] = useState<boolean>(false);
  useEffect(() => {
    if (createDisputeTransaction) {
      const daysSinceTransaction = differenceInDays(
        new Date(),
        new Date(createDisputeTransaction.createdAt),
      );
      const isDisputable =
        daysSinceTransaction <= STRIPE_TIME_LIMIT_TO_RAISE_DISPUTE_IN_DAYS;
      setDaysSinceTransaction(daysSinceTransaction);
      setIsDisputable(isDisputable);
    } else {
      setDaysSinceTransaction(0);
      setIsDisputable(true);
    }
  }, [createDisputeTransaction]);

  const handleSubmitDispute = async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix this gross mutatative stuff
    const transaction: any = createDisputeTransaction;
    track({
      label: 'Submit dispute',
      type: TrackType.action,
      actionType: 'submit',
      disputeReason,
      transactionId: transaction?.id,
      transactionStripeId: transaction?.stripeId,
      disputeAmountCents: transaction?.amountCents,
      disputeCreatedAt: new Date().toISOString(),
    });
    let isDisputeReady = false;
    if (disputeReason == 'fraudulent') {
      if (explanation != undefined && explanation.length > 0) {
        transaction.disputeReason = disputeReason;
        transaction.explanation = explanation;
        isDisputeReady = true;
      } else {
        //fill out required field please
      }
    } else if (disputeReason == 'not_received') {
      if (
        productType != undefined &&
        expectedDate != undefined &&
        productDescription != undefined &&
        productDescription.length > 0
      ) {
        transaction.disputeReason = disputeReason;
        transaction.productType = productType;
        transaction.expectedDate = expectedDate;
        transaction.productDescription = productDescription;
        isDisputeReady = true;
      } else {
        //fill out required fields please
      }
    } else if (disputeReason == 'duplicate') {
      if (
        originalTransaction != undefined &&
        createDisputeTransaction != undefined &&
        isBefore(
          new Date(originalTransaction.createdAt),
          new Date(createDisputeTransaction.createdAt),
        )
      ) {
        transaction.disputeReason = disputeReason;
        transaction.originalTransaction = {
          ...originalTransaction,
          id: originalTransaction.stripeId,
        };
        isDisputeReady = true;
      } else {
        console.log('please fill out the required fields');
      }
    } else if (disputeReason == 'merchandise_not_as_described') {
      if (
        receivedDate != undefined &&
        returnStatus != undefined &&
        returnDescription != undefined &&
        explanation != undefined &&
        returnDate != undefined
      ) {
        transaction.disputeReason = disputeReason;
        transaction.receivedDate = receivedDate;
        transaction.returnDate = returnDate;
        transaction.returnStatus = returnStatus;
        transaction.returnDescription = returnDescription;
        transaction.explanation = explanation;
        isDisputeReady = true;
      } else {
        console.log('please fill out the required fields');
      }
    } else if (disputeReason == 'service_not_as_described') {
      if (
        receivedDate != undefined &&
        cancelDate != undefined &&
        cancelReason != undefined &&
        explanation != undefined
      ) {
        transaction.disputeReason = disputeReason;
        transaction.receivedDate = receivedDate;
        transaction.cancelDate = cancelDate;
        transaction.cancelReason = cancelReason;
        transaction.explanation = explanation;
        isDisputeReady = true;
      } else {
        console.log('please fill out the required fields');
      }
    } else if (disputeReason == 'canceled') {
      if (
        productType != undefined &&
        expectedDate != undefined &&
        cancelDate != undefined &&
        productDescription != undefined &&
        productDescription.length > 0 &&
        explanation != undefined
      ) {
        transaction.disputeReason = disputeReason;
        transaction.productType = productType;
        transaction.productDescription = productDescription;
        transaction.expectedDate = expectedDate;
        transaction.cancelDate = cancelDate;
        transaction.cancellationPolicyProvided = cancellationPolicyProvided;
        transaction.explanation = explanation;
        isDisputeReady = true;
      } else {
        console.log('please fill out the required fields');
      }
    } else if (disputeReason == 'other') {
      if (
        productType != undefined &&
        productDescription != undefined &&
        explanation != undefined &&
        explanation.length > 0
      ) {
        transaction.disputeReason = disputeReason;
        transaction.productType = productType;
        transaction.productDescription = productDescription;
        transaction.explanation = explanation;
        isDisputeReady = true;
      } else {
        console.log('please fill out the required fields');
      }
    }
    if (isDisputeReady == true) {
      setOpenCreateDisputeDialog(false);
      await fetchApi
        .post(`${SERVICE_BENJI_CARD_URLS.DISPUTES_CREATE}`, {
          transaction: { ...transaction, id: transaction.stripeId },
        })
        .then(() => {
          const transactionsBaseQueryKey = queryKeys.transactions._baseKey;
          queryClient.invalidateQueries(transactionsBaseQueryKey);
          queryClient.invalidateQueries([
            ...queryKeys.paginatedData._baseKey,
            ...transactionsBaseQueryKey,
          ]);
          const disputesBaseQueryKey = queryKeys.disputes._baseKey;
          queryClient.invalidateQueries(disputesBaseQueryKey);
          queryClient.invalidateQueries([
            ...queryKeys.paginatedData._baseKey,
            ...disputesBaseQueryKey,
          ]);
          track({
            label: 'Disputed transaction',
            type: TrackType.effect,
            isSuccessful: true,
            disputeReason,
            // TODO: add these once the BE returns them
            // transactionId,
            // disputeId,
            // disputeAmountCents,
            // disputeCreatedAt,
            // disputeStatus,
          });
          incrementMixpanelProfileData('createDisputeCount');
        })
        .catch(() => {
          track({
            label: 'Disputed transaction',
            type: TrackType.effect,
            isSuccessful: false,
            disputeReason,
            // TODO: add these once the BE returns them
            // transactionId,
            // disputeId,
            // disputeAmountCents,
            // disputeCreatedAt,
            // disputeStatus,
          });
        });
    }
  };

  const columns: ({ width: string } & Column<DisputeCleaned>)[] = [
    {
      label: 'Date',
      renderCell: () => {
        return <span />;
      },
      width: '130px',
    },
    {
      label: 'Payment',
      renderCell: ({ paymentMethod }) => {
        return <span className="highlight-block">{paymentMethod}</span>;
      },
      width: 'minmax(200px, 300px)',
    },
    {
      label: 'Merchant',
      renderCell: ({ merchantName }) => {
        return merchantName;
      },
      width: 'minmax(150px, 250px)',
    },
    {
      label: 'Status',
      renderCell: ({ status }) => {
        return (
          <Box
            css={css`
              display: flex;
            `}
          >
            <StatusChip status={status} type="dispute" />
          </Box>
        );
      },
      width: '130px',
    },
    {
      label: 'Amount',
      renderCell: ({ amountCents }) => {
        return <FancyCurrencyDisplay amountCents={amountCents ?? 0} isPlain />;
      },
      width: '140px',
    },
    {
      label: 'Reason',
      renderCell: ({ reason }) => {
        return startCase(reason);
      },
      width: 'minmax(150px, 400px)',
    },
    ...(features?.groups
      ? [
          {
            label: 'Group',
            renderCell: ({ group }: DisputeCleaned) => group?.name,
            width: 'minmax(150px, 250px)',
          },
        ]
      : []),
    {
      // Spacer column to fill the remaining space
      label: '',
      renderCell: () => null,
      width: '1fr',
    },
  ];

  const isPageLoading = areUserPrivilegesLoading || isUserLoading;
  const [
    originalTransactionSearchInputValue,
    setOriginalTransactionSearchInputValue,
  ] = useState('');
  const [originalTransactionSearchValue, setOriginalTransactionSearchValue] =
    useState('');
  const debouncerOriginalTransactions = useRef(
    debounce((value) => {
      setOriginalTransactionSearchValue(value);
    }, 1000),
  );
  const handleSetOriginalTransactionSearchValue = useCallback(
    (value: string) => debouncerOriginalTransactions.current(value),
    [],
  );
  const {
    data: originalTransactions,
    isLoading: isLoadingOriginalTransactions,
  } = useGetPaginatedData({
    endpointUrl: SERVICE_BENJI_CARD_URLS.TRANSACTIONS_GET_COMPLETED_ALL,
    queryKeyBase: [...queryKeys.transactions._searchBaseKey(), 'original'],
    filters: {
      search: originalTransactionSearchValue,
    },
    dataFormatter: cleanTransactions,
    requiredPrivileges: [Privilege.transactions],
    autoFetchNextPage: false,
    enabled: !!originalTransactionSearchValue,
  });

  const [transactionSearchInputValue, setTransactionSearchInputValue] =
    useState('');
  const [transactionSearchValue, settransactionSearchValue] = useState('');
  const debouncer = useRef(
    debounce((value) => {
      settransactionSearchValue(value);
    }, 1000),
  );
  const handleSetTransactionSearchValue = useCallback(
    (value: string) => debouncer.current(value),
    [],
  );

  const { data: transactions, isLoading: isLoadingTransactions } =
    useGetPaginatedData({
      endpointUrl: SERVICE_BENJI_CARD_URLS.TRANSACTIONS_GET_COMPLETED_ALL,
      queryKeyBase: [...queryKeys.transactions._searchBaseKey(), 'current'],
      filters: {
        dispute: null,
        search: transactionSearchValue,
      },
      dataFormatter: cleanTransactions,
      requiredPrivileges: [Privilege.transactions],
      autoFetchNextPage: false,
    });

  const [filters, setFilters] = useState<PaginatedTableFilter>({});

  return (
    <>
      <Box
        css={css`
          max-height: calc(100vh - 100px);
          height: 100%;
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
        `}
      >
        <Card
          component="div"
          css={css`
            display: flex;
            width: 100%;
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            gap: 8px;
            flex: 1 1 auto;
          `}
          ref={cardRef}
        >
          <PaginatedTableWrapper<DisputeRaw, DisputeCleaned>
            endpointUrl={SERVICE_BENJI_CARD_URLS.DISPUTES_GET_ALL}
            queryKeyBase={queryKeys.disputes._baseKey}
            dataFormatter={cleanDisputes}
            requiredPrivileges={[Privilege.dispute]}
            filters={filters}
          >
            {({
              queryResult: { isLoading, isError },
              pagination,
              paginationData,
              resultCount,
              pageCount,
              searchProps,
            }) => (
              <>
                <Box
                  css={css`
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    align-content: center;
                    gap: 16px;
                    padding: 16px 16px 0;
                  `}
                >
                  <Typography
                    variant="h2"
                    css={css`
                      flex-grow: 999;
                    `}
                  >
                    Disputes
                  </Typography>
                  <Box
                    css={css`
                      display: flex;
                      flex-wrap: wrap-reverse;
                      gap: 16px;
                      flex-grow: 1;
                      justify-content: flex-end;
                    `}
                  >
                    <SearchBar {...searchProps} isLoading={!!isLoading} />
                    {!!privileges?.create_dispute && (
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => {
                          navigate('/secure/disputes/new-dispute');
                          // setOpenCreateDisputeDialog(true);
                        }}
                        disabled={
                          isPageLoading ||
                          userData?.accountDetails.status !==
                            AccountStatus.ACTIVE
                        }
                        startIcon={
                          <DisputesIcon
                            css={css`
                              fill: ${COLOR_PALETTE.textOnLight};
                            `}
                          />
                        }
                      >
                        Create Dispute
                      </Button>
                    )}
                  </Box>
                </Box>
                <Box
                  css={css`
                    width: 100%;
                    display: flex;
                    gap: 8px 16px;
                    flex-wrap: nowrap;
                    padding: 0 16px;
                  `}
                >
                  <TableFilters
                    setFilters={setFilters}
                    priorityFilterCount={0}
                    filters={[
                      {
                        name: 'groupId',
                        label: 'Viewing Single Group',
                        defaultValue: '',
                        type: FilterType.CHIP,
                      },
                    ]}
                  />
                </Box>
                <Table<DisputeCleaned>
                  data={paginationData}
                  pagination={pagination}
                  columns={columns}
                  isLoading={isLoading || isPageLoading}
                  combinedFirstColumn={{
                    primaryColumnKey: 'prettyCreatedAtDate',
                    columnComparatorKey: 'isCreatedAtDateSameAsPrev',
                  }}
                  onRowClick={({ stripeId }) => openDisputeModal(stripeId)}
                  columnWidths={columns.map(({ width }) => width).join(' ')}
                  css={css`
                    margin-top: 10px;
                    width: 100%;
                  `}
                />
                <TableLoader isLoading={isLoading} />
                <TableError isLoading={isLoading} isError={isError} />
                <TableNoResults
                  isLoading={isLoading}
                  resultCount={resultCount}
                  searchTerms={searchProps.searchValue}
                  noResultMessage={`Please try a different search term. You can search by the merchant name, the cardholder${`'`}s name or the card's last four digits.`}
                />
                <TablePagination
                  resultCount={resultCount}
                  pageCount={pageCount}
                  pagination={pagination}
                />
              </>
            )}
          </PaginatedTableWrapper>
        </Card>
      </Box>
      <Dialog
        open={openCreateDisputeDialog}
        onClose={() => {
          setOpenCreateDisputeDialog(false);
          // setCreateDisputeTransaction(undefined);
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Create New Dispute</DialogTitle>
        <DialogContent
          css={css`
            min-height: 400px;
          `}
        >
          <Autocomplete
            disablePortal
            options={transactions?.results ?? []}
            getOptionKey={({ id }) => id}
            getOptionLabel={() => transactionSearchInputValue}
            onChange={(_e, value) => {
              setCreateDisputeTransaction(value ?? undefined);
            }}
            onInputChange={(_e, value) => {
              setTransactionSearchInputValue(value);
              handleSetTransactionSearchValue(value);
            }}
            loading={!!transactionSearchValue && isLoadingTransactions}
            slotProps={{
              paper: {
                sx: {
                  maxHeight: '300px',
                  '& *': {
                    maxHeight: '300px',
                  },
                },
              },
            }}
            // overriding filter options to allow for filtering to happen on BE.
            filterOptions={(x) => x}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.id}>
                <Typography variant="body1">
                  {option.prettyCreatedAtDate},{' '}
                  <FancyCurrencyDisplay
                    amountCents={option.amountCents}
                    css={css`
                      display: inline-block;
                    `}
                  />
                  , {option.merchantName}, {option.paymentMethod}
                </Typography>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                className="highlight-block"
                label="Transaction"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {!!transactionSearchValue && isLoadingTransactions ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                css={css`
                  margin-top: 8px;
                `}
              />
            )}
          />

          {createDisputeTransaction ? (
            <>
              <FormControl fullWidth style={{ marginTop: 10 }}>
                <InputLabel id="DisputeReasonLabel">Reason: </InputLabel>
                <Select
                  labelId="transactionDateLabel"
                  id="transactionDateLabelSelect"
                  value={disputeReason}
                  label="Reason"
                  onChange={(e) => setDisputeReason(e.target.value)}
                >
                  <MenuItem value={'fraudulent'}>Fraudulent</MenuItem>
                  <MenuItem value={'not_received'}>Not Received</MenuItem>
                  <MenuItem value={'duplicate'}>Duplicate</MenuItem>
                  <MenuItem value={'merchandise_not_as_described'}>
                    Merchandise Not As Described
                  </MenuItem>
                  <MenuItem value={'service_not_as_described'}>
                    Service Not As Described
                  </MenuItem>
                  <MenuItem value={'canceled'}>Canceled</MenuItem>
                  <MenuItem value={'other'}>Other</MenuItem>
                </Select>
              </FormControl>

              {disputeReason === 'fraudulent' ? (
                <FormControl>
                  <TextField
                    className="highlight-block"
                    style={{ marginTop: '1vh' }}
                    id="outlined-multiline-flexible"
                    label="Explanation"
                    multiline
                    maxRows={4}
                    onChange={(e) => setExplanation(e.target.value)}
                  />
                </FormControl>
              ) : (
                <></>
              )}
              {disputeReason === 'not_received' ? (
                <Col>
                  <FormControl fullWidth style={{ marginTop: 10 }}>
                    <InputLabel id="Product Type">Product Type: </InputLabel>
                    <Select
                      labelId="transactionDateLabel"
                      id="transactionDateLabelSelect"
                      value={productType}
                      label="Product Type"
                      onChange={(e) => {
                        setProductType(e.target.value);
                      }}
                    >
                      <MenuItem value={'merchandise'}>Merchandise</MenuItem>
                      <MenuItem value={'service'}>Service</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <DialogContentText style={{ marginTop: '1vh' }}>
                      Expected Date:
                    </DialogContentText>
                    <Input
                      onChange={(e) => {
                        setExpectedDate(e.target.value);
                      }}
                      style={{ width: '20vw' }}
                      type={'date'}
                      className="highlight-block"
                    ></Input>
                  </FormControl>
                  <FormControl style={{ width: '100%' }}>
                    <TextField
                      className="highlight-block"
                      style={{ marginTop: '1vh' }}
                      id="outlined-multiline-flexible"
                      label="Product Description"
                      multiline
                      maxRows={4}
                      onChange={(e) => setProductDescription(e.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      className="highlight-block"
                      style={{ marginTop: '1vh' }}
                      id="outlined-multiline-flexible"
                      label="Explanation"
                      multiline
                      maxRows={4}
                      onChange={(e) => setExplanation(e.target.value)}
                    />
                  </FormControl>
                </Col>
              ) : (
                <></>
              )}

              {disputeReason === 'duplicate' ? (
                <>
                  <Autocomplete
                    disablePortal
                    options={originalTransactions?.results ?? []}
                    getOptionKey={({ id }) => id}
                    getOptionLabel={() => originalTransactionSearchInputValue}
                    onChange={(_e, value) => {
                      setOriginalTransaction(value ?? undefined);
                    }}
                    onInputChange={(_e, value) => {
                      setOriginalTransactionSearchInputValue(value);
                      handleSetOriginalTransactionSearchValue(value);
                    }}
                    loading={
                      !!originalTransactionSearchValue &&
                      isLoadingOriginalTransactions
                    }
                    slotProps={{
                      paper: {
                        sx: {
                          maxHeight: '300px',
                          '& *': {
                            maxHeight: '300px',
                          },
                        },
                      },
                    }}
                    // overriding filter options to allow for filtering to happen on BE.
                    filterOptions={(x) => x}
                    renderOption={(props, option) => (
                      <Box component="li" {...props} key={option.id}>
                        <Typography variant="body1">
                          {option.prettyCreatedAtDate},{' '}
                          <FancyCurrencyDisplay
                            amountCents={option.amountCents}
                            css={css`
                              display: inline-block;
                            `}
                          />
                          , {option.merchantName}, {option.paymentMethod}
                        </Typography>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="highlight-block"
                        label="Original Transaction"
                        autoComplete="off"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {!!originalTransactionSearchValue &&
                              isLoadingOriginalTransactions ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                        css={css`
                          margin-top: 8px;
                        `}
                      />
                    )}
                  />

                  {originalTransaction?.createdAt &&
                  createDisputeTransaction?.createdAt &&
                  isBefore(
                    new Date(originalTransaction.createdAt),
                    new Date(createDisputeTransaction.createdAt),
                  ) ? (
                    <></>
                  ) : (
                    <p style={{ color: 'red' }}>
                      Original Transaction be older than duplicate transaction.
                    </p>
                  )}
                  {!!originalTransaction && (
                    <Typography>
                      {originalTransaction.prettyCreatedAtDate},{' '}
                      <FancyCurrencyDisplay
                        amountCents={originalTransaction.amountCents}
                        css={css`
                          display: inline-block;
                        `}
                      />
                      , {originalTransaction.merchantName},{' '}
                      {originalTransaction.paymentMethod}
                    </Typography>
                  )}
                </>
              ) : (
                <></>
              )}
              {disputeReason === 'merchandise_not_as_described' ? (
                <>
                  <FormControl fullWidth>
                    <DialogContentText style={{ marginTop: '1vh' }}>
                      Recieved Date:
                    </DialogContentText>
                    <Input
                      onChange={(e) => {
                        setReceivedDate(e.target.value);
                      }}
                      style={{ width: '20vw' }}
                      type={'date'}
                      className="highlight-block"
                    ></Input>
                  </FormControl>
                  <FormControl fullWidth style={{ marginTop: '1vh' }}>
                    <InputLabel id="returnStatusLabel">
                      Return Status:{' '}
                    </InputLabel>
                    <Select
                      labelId="returnStatusLabel"
                      id="returnStatus"
                      value={returnStatus}
                      label="Product Type"
                      onChange={(e) => setReturnStatus(e.target.value)}
                    >
                      <MenuItem value="successful">Accepted</MenuItem>
                      <MenuItem value="merchant_rejected">Rejected</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <DialogContentText style={{ marginTop: '1vh' }}>
                      Returned Date:
                    </DialogContentText>
                    <Input
                      onChange={(e) => {
                        setReturnDate(e.target.value);
                      }}
                      style={{ width: '20vw' }}
                      type={'date'}
                      className="highlight-block"
                    ></Input>
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      className="highlight-block"
                      style={{ marginTop: '1vh' }}
                      id="outlined-multiline-flexible"
                      label="Return Description"
                      multiline
                      maxRows={4}
                      onChange={(e) => setReturnDescription(e.target.value)}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      className="highlight-block"
                      style={{ marginTop: '1vh' }}
                      id="outlined-multiline-flexible"
                      label="Explanation"
                      multiline
                      maxRows={4}
                      onChange={(e) => setExplanation(e.target.value)}
                    />
                  </FormControl>
                </>
              ) : (
                <></>
              )}
              {disputeReason === 'service_not_as_described' ? (
                <>
                  <FormControl fullWidth>
                    <DialogContentText style={{ marginTop: '1vh' }}>
                      Recieved Date:
                    </DialogContentText>
                    <Input
                      onChange={(e) => {
                        setReceivedDate(e.target.value);
                      }}
                      style={{ width: '20vw' }}
                      type={'date'}
                      className="highlight-block"
                    ></Input>
                  </FormControl>
                  <FormControl fullWidth>
                    <DialogContentText style={{ marginTop: '1vh' }}>
                      Cancellation Date:
                    </DialogContentText>
                    <Input
                      onChange={(e) => {
                        setCancelDate(e.target.value);
                      }}
                      style={{ width: '20vw' }}
                      type={'date'}
                      className="highlight-block"
                    ></Input>
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      className="highlight-block"
                      style={{ marginTop: '1vh' }}
                      id="outlined-multiline-flexible"
                      label="Cancellation reason"
                      multiline
                      maxRows={4}
                      onChange={(e) => setCancelReason(e.target.value)}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      className="highlight-block"
                      style={{ marginTop: '1vh' }}
                      id="outlined-multiline-flexible"
                      label="Explanation"
                      multiline
                      maxRows={4}
                      onChange={(e) => setExplanation(e.target.value)}
                    />
                  </FormControl>
                </>
              ) : (
                <></>
              )}

              {disputeReason === 'canceled' ? (
                <>
                  <FormControl fullWidth style={{ marginTop: 10 }}>
                    <InputLabel id="Product Type">Product Type: </InputLabel>
                    <Select
                      labelId="transactionDateLabel"
                      id="transactionDateLabelSelect"
                      value={productType}
                      label="Product Type"
                      onChange={(e) => {
                        setProductType(e.target.value);
                      }}
                    >
                      <MenuItem value={'merchandise'}>Merchandise</MenuItem>
                      <MenuItem value={'service'}>Service</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <DialogContentText style={{ marginTop: '1vh' }}>
                      Expected Date:
                    </DialogContentText>
                    <Input
                      onChange={(e) => {
                        setExpectedDate(e.target.value);
                      }}
                      style={{ width: '20vw' }}
                      type={'date'}
                      className="highlight-block"
                    ></Input>
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      className="highlight-block"
                      style={{ marginTop: '1vh' }}
                      id="outlined-multiline-flexible"
                      label="Product Description"
                      multiline
                      maxRows={4}
                      onChange={(e) => setProductDescription(e.target.value)}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      className="highlight-block"
                      style={{ marginTop: '1vh' }}
                      id="outlined-multiline-flexible"
                      label="Explanation"
                      multiline
                      maxRows={4}
                      onChange={(e) => setExplanation(e.target.value)}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <DialogContentText style={{ marginTop: '1vh' }}>
                      Canceled Date:
                    </DialogContentText>
                    <Input
                      onChange={(e) => {
                        setCancelDate(e.target.value);
                      }}
                      style={{ width: '20vw' }}
                      type={'date'}
                      className="highlight-block"
                    ></Input>
                  </FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setCancellationPolicyProvided(!!e.target.value);
                        }}
                      />
                    }
                    label={'Cancellation Policy Provided?'}
                  />
                </>
              ) : (
                <></>
              )}
              {disputeReason === 'other' ? (
                <>
                  <FormControl fullWidth style={{ marginTop: 10 }}>
                    <InputLabel id="Product Type">Product Type: </InputLabel>
                    <Select
                      labelId="transactionDateLabel"
                      id="transactionDateLabelSelect"
                      value={productType}
                      label="Product Type"
                      onChange={(e) => {
                        setProductType(e.target.value);
                      }}
                    >
                      <MenuItem value={'merchandise'}>Merchandise</MenuItem>
                      <MenuItem value={'service'}>Service</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl style={{ width: '100%' }}>
                    <TextField
                      className="highlight-block"
                      style={{ marginTop: '1vh' }}
                      id="outlined-multiline-flexible"
                      label="Product Description"
                      multiline
                      maxRows={4}
                      onChange={(e) => setProductDescription(e.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      className="highlight-block"
                      style={{ marginTop: '1vh' }}
                      id="outlined-multiline-flexible"
                      label="Explanation"
                      multiline
                      maxRows={4}
                      onChange={(e) => setExplanation(e.target.value)}
                    />
                  </FormControl>
                </>
              ) : (
                <></>
              )}
              <Row style={{ marginTop: '2vh' }}>
                <DialogContentText>
                  Merchant: {createDisputeTransaction?.merchantName}
                </DialogContentText>
                <DialogContentText>
                  Date: {formatISODate(createDisputeTransaction.createdAt)}
                </DialogContentText>
                <DialogContentText>
                  Amount:{' '}
                  <FancyCurrencyDisplay
                    amountCents={createDisputeTransaction.amountCents}
                  />
                </DialogContentText>
                <DialogContentText>
                  Card: {createDisputeTransaction?.cardLast4}
                </DialogContentText>
              </Row>
            </>
          ) : (
            <></>
          )}
          {!isDisputable && (
            <Alert
              severity="warning"
              css={css`
                flex: 1 1 100%;
                margin-top: 24px;
                margin-bottom: 16px;
              `}
            >
              The transaction is {daysSinceTransaction} days old. Disputes can
              not be raised for transactions older than{' '}
              {STRIPE_TIME_LIMIT_TO_RAISE_DISPUTE_IN_DAYS} days.
            </Alert>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setOpenCreateDisputeDialog(false);
              setCreateDisputeTransaction(undefined);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmitDispute}
            disabled={!isDisputable}
          >
            Submit Dispute
          </Button>
        </DialogActions>
      </Dialog>
      <DisputeDetailsModal anchorEl={cardRef.current} />
    </>
  );
};

export default Disputes;
