import { css } from '@emotion/react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { debounce, get } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { useSearchParams } from 'react-router-dom';

import { FancyCurrencyDisplay, FormFieldCheckbox } from 'src/components';
import { useFundsAssignmentDetailsModal } from 'src/components/modals/FundsAssignmentDetailsModal';
import { useGetGroupDetails } from 'src/components/modals/GroupDetailsModal/useGetGroupDetails';
import { TrackType, useToast, useTrack } from 'src/context';
import {
  useAppRoutes,
  useGetAccountOverview,
  useGetPaginatedData,
  useGetUserDetails,
} from 'src/hooks';
import { cleanGroups } from 'src/pages/Groups/utils';
import { COLOR_PALETTE } from 'src/theme';
import { Privilege } from 'src/types';
import { GroupStatus, type GroupCleaned } from 'src/types/groups';
import { queryKeys, SERVICE_BENJI_CARD_URLS } from 'src/utils';

import { useAssignFunds } from './useAssignFunds';

import type { TextFieldProps } from '@mui/material';

const CurrencyTextField = (props: TextFieldProps) => {
  return (
    <TextField
      {...props}
      className={`${props.className} highlight-block`}
      sx={{
        input: {
          '&::placeholder': {
            opacity: 1,
            color: COLOR_PALETTE.lightTextOnLight,
          },
        },
      }}
      css={css`
        fieldset {
          border-radius: 8px;
          border-color: ${COLOR_PALETTE.border};
        }
      `}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
    />
  );
};

export const FormSection = ({
  label,
  children,
  className,
  labelWidth,
}: {
  label: string;
  children: React.ReactNode;
  className?: string;
  labelWidth?: number;
}) => (
  <Box
    css={css`
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 20px 30px;
      padding: 15px 0;
      border-bottom: 1px solid ${COLOR_PALETTE.border};
    `}
    className={className}
  >
    <Typography
      css={css`
        flex: 0 0 ${labelWidth ? labelWidth : '165'}px;
        padding-top: 15px;
        font-size: 15px;
        font-weight: 500;
      `}
    >
      {label}
    </Typography>
    <Box
      css={css`
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        flex: 1 1 400px;
      `}
    >
      {children}
    </Box>
  </Box>
);

export interface FormFields {
  groupId: string;
  amountCents: number | null;
  hasConfirmed: boolean;
}

const labelWidth = 220;

export const AssignFundsToGroupForm = () => {
  const { track, incrementMixpanelProfileData } = useTrack();
  const { generatePath } = useAppRoutes();
  const [searchParams] = useSearchParams();
  const selectedGroupId = searchParams.get('selectedGroupId');
  const { openModal: openFundsAssignmentDetailsModal } =
    useFundsAssignmentDetailsModal();

  const { data: accountOverview, isLoading: isBalanceLoading } =
    useGetAccountOverview();

  const { data: userDetails } = useGetUserDetails();

  const { setToast } = useToast();

  const defaultValues: FormFields = {
    groupId: '',
    amountCents: 0,
    hasConfirmed: false,
  };
  const useFormMethods = useForm<FormFields>({
    defaultValues,
    mode: 'onBlur',
    resetOptions: {
      keepDirtyValues: true,
    },
  });
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState,
    setValue,
    register,
    trigger,
  } = useFormMethods;
  const { isSubmitting, errors, dirtyFields } = formState;
  register('groupId', {
    required: 'Required',
  });

  const formValues = watch();

  const { mutateAsync: assignFunds } = useAssignFunds();

  const [hasDefaultBeenSet, setHasDefaultBeenSet] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<GroupCleaned | null>(null);
  const [groupSearchInputValue, setGroupSearchInputValue] = useState('');
  const [groupSearchValue, setGroupSearchValue] = useState('');
  const debouncer = useRef(
    debounce((value) => {
      setGroupSearchValue(value);
    }, 1000),
  );
  const handleSetCardholderSearchValue = useCallback(
    (value: string) => debouncer.current(value),
    [],
  );
  const { data: groups, isLoading: isLoadingGroups } = useGetPaginatedData({
    endpointUrl: SERVICE_BENJI_CARD_URLS.GROUPS_GET,
    queryKeyBase: queryKeys.groups._baseKey,
    filters: {
      search: groupSearchValue,
      status: GroupStatus.ACTIVE,
    },
    dataFormatter: cleanGroups,
    requiredPrivileges: [Privilege.cards],
    autoFetchNextPage: false,
  });
  useEffect(() => {
    if (dirtyFields.groupId) {
      trigger('groupId');
    }
  }, [selectedGroup?.id, trigger, dirtyFields.groupId]);
  const { data: defaultGroup } = useGetGroupDetails({
    id: selectedGroupId ?? undefined,
  });

  useEffect(() => {
    if (defaultGroup && !selectedGroup && !hasDefaultBeenSet) {
      setSelectedGroup(defaultGroup);
      setValue('groupId', defaultGroup.id);
      setHasDefaultBeenSet(true);
    }
  }, [defaultGroup, hasDefaultBeenSet, selectedGroup, setValue]);

  const onSubmit = async (values: FormFields) => {
    track({
      label: 'Submit fund assignment to group',
      type: TrackType.action,
      actionType: 'submit',
      groupId: values.groupId,
      amountCents: values.amountCents,
    });
    await assignFunds(
      {
        groupId: values.groupId,
        amountCents: values.amountCents ?? 0,
      },
      {
        onSuccess: ({ id }) => {
          track({
            label: 'Created fund assignment to group',
            type: TrackType.effect,
            isSuccessful: true,
            groupId: values.groupId,
            amountCents: values.amountCents,
          });
          incrementMixpanelProfileData('createFundsAssignment');
          setToast({
            message: `Funds assigned to group successfully`,
            severity: 'success',
          });
          openFundsAssignmentDetailsModal(id);
        },
        onError: (error) => {
          const errorCode = get(error, 'response.data.code');

          track({
            label: 'Create fund assignment to group',
            type: TrackType.effect,
            isSuccessful: false,
            groupId: values.groupId,
            amountCents: values.amountCents,
            errorCode,
          });
          reset(undefined, { keepValues: true });
          setToast({
            message: 'Error Assigning Funds',
            severity: 'error',
          });
        },
      },
    );
  };

  return (
    <FormProvider {...useFormMethods}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit, (errors) => {
          () => {
            track({
              label: 'Submit fund assignment to group',
              type: TrackType.action,
              actionType: 'submit',
              groupId: formValues.groupId,
              amountCents: formValues.amountCents,
              validationErrors: Object.entries(errors).map(([key, value]) => ({
                field: key,
                message: value.message,
              })),
            });
          };
        })}
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 683px;
          margin: 0 auto;
        `}
      >
        <FormSection label="Unassigned Account Balance" labelWidth={labelWidth}>
          <Typography
            css={css`
              padding-top: 15px;
            `}
          >
            {isBalanceLoading ? (
              'Loading...'
            ) : (
              <FancyCurrencyDisplay
                size="md"
                amountCents={
                  accountOverview?.accountBalance.unassignedAvailableCents
                }
              />
            )}
          </Typography>
        </FormSection>
        <FormSection label="To Group" labelWidth={labelWidth}>
          <Autocomplete
            id="group-search"
            disablePortal
            options={groups?.results ?? []}
            getOptionKey={({ id }) => id}
            getOptionLabel={(option) =>
              option ? option.name : groupSearchInputValue
            }
            onBlur={() => {
              trigger('groupId');
              trigger('amountCents');
            }}
            onChange={(_e, value) => {
              setSelectedGroup(value);
              setValue('groupId', value?.id ?? '');
              setValue('hasConfirmed', false);
            }}
            noOptionsText={
              <>
                No options.{' '}
                <Link href={generatePath('/secure/groups/new-group')}>
                  Create New Group
                </Link>
              </>
            }
            value={selectedGroup}
            onInputChange={(_e, value) => {
              setGroupSearchInputValue(value);
              handleSetCardholderSearchValue(value);
            }}
            inputValue={groupSearchInputValue}
            loading={!!groupSearchValue && isLoadingGroups}
            slotProps={{
              paper: {
                sx: {
                  maxHeight: '300px',
                  '& *': {
                    maxHeight: '300px',
                  },
                },
              },
            }}
            css={css`
              width: 100%;
            `}
            // overriding filter options to allow for filtering to happen on BE.
            filterOptions={(x) => x}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                <Typography variant="body1">{option.name}</Typography>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Group"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {!!groupSearchValue && isLoadingGroups ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                error={errors.groupId ? true : false}
                helperText={errors.groupId ? errors.groupId.message : null}
                css={css`
                  fieldset {
                    border-radius: 10px;
                  }
                  .Mui-focused fieldset {
                    box-shadow: 0px 10px 15px 0px rgba(242, 190, 87, 0.19);
                  }
                `}
              />
            )}
          />
        </FormSection>
        <FormSection
          label="Amount"
          labelWidth={labelWidth}
          css={css`
            min-height: 98px;
          `}
        >
          <Controller
            control={control}
            name="amountCents"
            defaultValue={
              defaultValues.amountCents ? defaultValues.amountCents / 100 : null
            }
            rules={{
              max: {
                value:
                  accountOverview?.accountBalance.unassignedAvailableCents ?? 0,
                message:
                  'Must be less than or equal to unassigned account balance',
              },
              min: {
                value: 1,
                message: 'Required',
              },

              required: 'Required',
            }}
            render={({ field }) => {
              return (
                <NumericFormat
                  name={field.name}
                  onBlur={() => {
                    trigger(field.name);
                    field.onBlur();
                  }}
                  value={(field.value ?? 0) / 100 || ''}
                  error={!!errors.amountCents}
                  customInput={CurrencyTextField}
                  type="text"
                  allowNegative={false}
                  thousandSeparator
                  fixedDecimalScale
                  decimalScale={2}
                  variant="outlined"
                  placeholder="None"
                  helperText={
                    errors.amountCents
                      ? (errors.amountCents.message as string)
                      : ''
                  }
                  onValueChange={(values) => {
                    setValue(
                      field.name,
                      values.floatValue ? values.floatValue * 100 : null,
                    );
                    setValue('hasConfirmed', false);
                  }}
                  css={css`
                    flex: 1 1 200px;
                  `}
                />
              );
            }}
          />
        </FormSection>

        <FormFieldCheckbox<FormFields>
          name="hasConfirmed"
          rules={{
            required: 'Required',
          }}
          label={
            <Typography
              css={css`
                a {
                  color: ${COLOR_PALETTE.blueLink};
                  text-decoration: none;
                }
              `}
            >
              I confirm that I am assigning{' '}
              <FancyCurrencyDisplay
                isPlain
                amountCents={formValues.amountCents ?? undefined}
              />{' '}
              to {selectedGroup?.name ?? '_'}.
            </Typography>
          }
          defaultValue={defaultValues.hasConfirmed}
          control={control}
          css={css`
            margin-top: 20px;
            .MuiFormControlLabel-root {
              align-items: center;
            }
          `}
        />

        <Box
          css={css`
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
          `}
        >
          <Button
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            onClick={() => {
              track({
                label: 'Submit fund assignment to group',
                type: TrackType.action,
                actionType: 'submit',
              });
            }}
          >
            Assign Funds
          </Button>
          {userDetails?.accountDetails.features.treasury && (
            <Typography
              variant="body2"
              css={css`
                flex: 1 1 100%;
                padding: 16px 0;
                color: ${COLOR_PALETTE.lightTextOnLight};
              `}
            >
              Assigning funds may take a few minutes before they are available.
            </Typography>
          )}
        </Box>
      </Box>
    </FormProvider>
  );
};
