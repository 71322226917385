// See: https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories

import { isArray } from 'lodash';

// For consistency, query key factories should always be a function with a single argument that accepts an object
// containing all the query arguments needed to produce the query key.

const groupsQueryKeys = {
  _baseKey: ['GROUPS'] as const,
  details: (data: { id: string }) =>
    [...groupsQueryKeys._baseKey, 'DETAILS', data] as const,
};

const fundsAssignmentsQueryKeys = {
  _baseKey: ['FUNDS_ASSIGNMENTS'] as const,
  details: (data: { id: string }) =>
    [...fundsAssignmentsQueryKeys._baseKey, 'DETAILS', data] as const,
};

const cardholdersQueryKeys = {
  _baseKey: ['CARDHOLDERS'] as const,
  details: (data: { id: string }) =>
    [...cardholdersQueryKeys._baseKey, 'DETAILS', data] as const,
};

const cardsQueryKeys = {
  _baseKey: ['CARDS'] as const,
  details: (data: { id: string }) =>
    [...cardsQueryKeys._baseKey, 'DETAILS', data] as const,
  _sendCardHistoryBaseKey: (data: { id: string }) =>
    [...cardsQueryKeys._baseKey, 'SEND_CARD_HISTORY', data] as const,
  _cardSpendLimitHistoryBaseKey: () =>
    [...cardsQueryKeys._baseKey, 'CARD_SPEND_LIMIT_HISTORY'] as const,
};

const viewCardQueryKeys = {
  _baseKey: ['VIEW_CARD'] as const,
  details: (data: { token: string }) =>
    [...viewCardQueryKeys._baseKey, 'DETAILS', data] as const,
};

const accountQueryKeys = {
  _baseKey: ['ACCOUNT'] as const,
  privileges: () => [...accountQueryKeys._baseKey, 'PRIVILEGES'] as const,
  details: (data: { userId: string }) =>
    [...accountQueryKeys._baseKey, 'DETAILS', data] as const,
  fundingInstructions: () =>
    [...accountQueryKeys._baseKey, 'FUNDING_INSTRUCTIONS'] as const,
  stripeOnboardingLink: () =>
    [...accountQueryKeys._baseKey, 'STRIPE_ONBOARDING_LINK'] as const,
};

const transactionsQueryKeys = {
  _baseKey: ['TRANSACTIONS'] as const,
  _searchBaseKey: () => [...transactionsQueryKeys._baseKey, 'SEARCH'] as const,
  details: (data: { id: string; type: string }) =>
    [...transactionsQueryKeys._baseKey, 'DETAILS', data] as const,
};
const disputesQueryKeys = {
  _baseKey: ['DISPUTES'] as const,
  all: (data: { userEmail: string; page: number; pageSize: number }) =>
    [...disputesQueryKeys._baseKey, 'ALL', data] as const,
  details: (data: { id: string }) =>
    [...disputesQueryKeys._baseKey, 'DETAILS', data] as const,
};

const balanceQueryKeys = {
  _baseKey: ['BALANCE'] as const,
  current: () => [...balanceQueryKeys._baseKey, 'CURRENT'] as const,
  month: (data: { year: number; month: number }) =>
    [...balanceQueryKeys._baseKey, 'CURRENT', data] as const,
  fundTransferDetails: (data: { id: string; type: string }) =>
    [...balanceQueryKeys._baseKey, 'FUND_TRANSFER_DETAILS', data] as const,
};

const subUsersQueryKeys = {
  _baseKey: ['SUB_USERS'] as const,
  all: (data: { userEmail: string; page: number; pageSize: number }) =>
    [...disputesQueryKeys._baseKey, 'ALL', data] as const,
  details: (data: { id: string }) =>
    [...disputesQueryKeys._baseKey, 'DETAILS', data] as const,
};

const paginatedDataKeys = {
  _baseKey: ['PAGINATED_DATA'] as const,
  page: (
    baseKey: string | unknown[] | readonly unknown[],
    data: {
      page: number;
      pageSize: number;
      filters?: unknown;
    },
  ) =>
    isArray(baseKey)
      ? ([...paginatedDataKeys._baseKey, ...baseKey, data] as const)
      : ([...paginatedDataKeys._baseKey, baseKey, data] as const),
};

export const queryKeys = {
  groups: groupsQueryKeys,
  fundsAssignments: fundsAssignmentsQueryKeys,
  cardholders: cardholdersQueryKeys,
  cards: cardsQueryKeys,
  viewCard: viewCardQueryKeys,
  subUsers: subUsersQueryKeys,
  account: accountQueryKeys,
  transactions: transactionsQueryKeys,
  disputes: disputesQueryKeys,
  balance: balanceQueryKeys,
  paginatedData: paginatedDataKeys,
};
