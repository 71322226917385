import { useUser } from '@clerk/clerk-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { generatePath } from 'react-router-dom';

import { useFetch } from 'src/hooks/useFetch';
import { queryKeys, SERVICE_BENJI_CARD_URLS } from 'src/utils';

export const useUpdateUserInfo = () => {
  const { user } = useUser();

  const queryClient = useQueryClient();
  const { fetchApi } = useFetch();

  const updateUserInfo = async ({
    userId,
    name,
  }: {
    userId: string;
    name: {
      first: string;
      last: string;
    };
  }) => {
    const { data } = await fetchApi.put<{
      id: string;
      first_name: string;
      last_name: string;
      email: string;
    }>(
      generatePath(SERVICE_BENJI_CARD_URLS.USER_UPDATE_DETAILS, {
        userId,
      }),
      {
        name,
      },
    );
    return data;
  };

  return useMutation({
    mutationFn: (data: {
      userId: string;
      name: {
        first: string;
        last: string;
      };
    }) => updateUserInfo(data),
    onSuccess: () => {
      const userDetailsQueryKey = queryKeys.account.details({
        userId: user?.externalId ?? '',
      });
      queryClient.invalidateQueries(userDetailsQueryKey);
    },
  });
};
