import { useUser } from '@clerk/clerk-react';
import { useQuery } from '@tanstack/react-query';
import { startCase } from 'lodash';
import { generatePath } from 'react-router-dom';

import { TypeLabels } from 'src/pages/FundsTransfers/utils';
import { FundTransferTypeEnum } from 'src/types/funds-transfers';
import { SERVICE_BENJI_CARD_URLS, formatDateToMST, queryKeys } from 'src/utils';

import { useFetch } from './useFetch';
import { useGetUserPrivileges } from './useGetUserPrivileges';

import type {
  FundsTransferDetailsRaw,
  OutboundTransferClean,
  OutboundTransferRaw,
  PayoutClean,
  PayoutRaw,
  ReceivedCreditClean,
  ReceivedCreditRaw,
  ReceivedDebitClean,
  ReceivedDebitRaw,
  TopUpClean,
  TopUpRaw,
} from 'src/types/funds-transfers';

export const useGetFundTransferDetails = ({
  id,
  type,
}: {
  id: string;
  type: FundTransferTypeEnum;
}) => {
  const { fetchApi } = useFetch();
  const { isSignedIn } = useUser();
  const { privileges } = useGetUserPrivileges();

  const getFundTransferDetails = async ({
    id,
    type,
  }: {
    id: string;
    type: FundTransferTypeEnum;
  }) => {
    const { data } = await fetchApi.get<FundsTransferDetailsRaw>(
      `${generatePath(SERVICE_BENJI_CARD_URLS.FUND_TRANSFER_GET_DETAILS, {
        fundTransferId: id,
        type,
      })}`,
    );
    if (type === FundTransferTypeEnum.RECEIVED_CREDIT) {
      const receivedCredit = data as ReceivedCreditRaw;
      const prettyCreatedAtDate = formatDateToMST(
        new Date(receivedCredit.created_at),
      );
      const cleanedData: ReceivedCreditClean = {
        id: receivedCredit.id,
        stripeId: receivedCredit.stripe_id,
        amountCents: receivedCredit.amount_cents,
        status: receivedCredit.status,
        createdAt: receivedCredit.created_at,
        prettyCreatedAtDate,
        description: receivedCredit.description,
        failureCode: receivedCredit.failure_code,
        initiatingPaymentMethod: {
          billingAddress:
            receivedCredit.initiating_payment_method_billing_address
              ? {
                  city: receivedCredit.initiating_payment_method_billing_address
                    ?.city,
                  line1:
                    receivedCredit.initiating_payment_method_billing_address
                      ?.line1,
                  line2:
                    receivedCredit.initiating_payment_method_billing_address
                      ?.line2 || null,
                  state:
                    receivedCredit.initiating_payment_method_billing_address
                      .state,
                  country:
                    receivedCredit.initiating_payment_method_billing_address
                      .country,
                  postalCode:
                    receivedCredit.initiating_payment_method_billing_address
                      .postal_code,
                }
              : undefined,
          billingEmail: receivedCredit.initiating_payment_method_billing_email,
          billingName: receivedCredit.initiating_payment_method_billing_name,
          type: receivedCredit.initiating_payment_method_type,
          bankAccountLast4:
            receivedCredit.initiating_payment_method_bank_account_last4,
          bankAccountName:
            receivedCredit.initiating_payment_method_bank_account_name,
        },
        network: receivedCredit.network,
        typeLabel:
          TypeLabels[FundTransferTypeEnum.RECEIVED_CREDIT] ??
          startCase(FundTransferTypeEnum.RECEIVED_CREDIT),
        entityType: FundTransferTypeEnum.RECEIVED_CREDIT,
      };
      return cleanedData;
    } else if (type === FundTransferTypeEnum.RECEIVED_DEBIT) {
      const receivedDebit = data as ReceivedDebitRaw;
      const prettyCreatedAtDate = formatDateToMST(
        new Date(receivedDebit.created_at),
      );
      const cleanedData: ReceivedDebitClean = {
        id: receivedDebit.id,
        stripeId: receivedDebit.stripe_id,
        amountCents: receivedDebit.amount_cents,
        status: receivedDebit.status,
        createdAt: receivedDebit.created_at,
        prettyCreatedAtDate,
        description: receivedDebit.description,
        failureCode: receivedDebit.failure_code,
        initiatingPaymentMethod: {
          billingAddress:
            receivedDebit.initiating_payment_method_billing_address
              ? {
                  city: receivedDebit.initiating_payment_method_billing_address
                    ?.city,
                  line1:
                    receivedDebit.initiating_payment_method_billing_address
                      ?.line1,
                  line2:
                    receivedDebit.initiating_payment_method_billing_address
                      ?.line2 || null,
                  state:
                    receivedDebit.initiating_payment_method_billing_address
                      .state,
                  country:
                    receivedDebit.initiating_payment_method_billing_address
                      .country,
                  postalCode:
                    receivedDebit.initiating_payment_method_billing_address
                      .postal_code,
                }
              : undefined,
          billingEmail: receivedDebit.initiating_payment_method_billing_email,
          billingName: receivedDebit.initiating_payment_method_billing_name,
          type: receivedDebit.initiating_payment_method_type,
          bankAccountLast4:
            receivedDebit.initiating_payment_method_bank_account_last4,
          bankAccountName:
            receivedDebit.initiating_payment_method_bank_account_name,
        },
        network: receivedDebit.network,
        typeLabel:
          TypeLabels[FundTransferTypeEnum.RECEIVED_DEBIT] ??
          startCase(FundTransferTypeEnum.RECEIVED_DEBIT),
        entityType: FundTransferTypeEnum.RECEIVED_DEBIT,
      };
      return cleanedData;
    } else if (type === FundTransferTypeEnum.OUTBOUND_TRANSFER) {
      const outboundTransfer = data as OutboundTransferRaw;
      const prettyCreatedAtDate = formatDateToMST(
        new Date(outboundTransfer.created_at),
      );
      const cleanedData: OutboundTransferClean = {
        id: outboundTransfer.id,
        stripeId: outboundTransfer.stripe_id,
        amountCents: outboundTransfer.amount_cents,
        status: outboundTransfer.status,
        createdAt: outboundTransfer.created_at,
        prettyCreatedAtDate,
        description: outboundTransfer.description,
        destinationPaymentMethod: {
          billingAddress:
            outboundTransfer.destination_payment_method_billing_address
              ? {
                  city: outboundTransfer
                    .destination_payment_method_billing_address?.city,
                  line1:
                    outboundTransfer.destination_payment_method_billing_address
                      ?.line1,
                  line2:
                    outboundTransfer.destination_payment_method_billing_address
                      ?.line2 || null,
                  state:
                    outboundTransfer.destination_payment_method_billing_address
                      .state,
                  country:
                    outboundTransfer.destination_payment_method_billing_address
                      .country,
                  postalCode:
                    outboundTransfer.destination_payment_method_billing_address
                      .postal_code,
                }
              : undefined,
          billingEmail:
            outboundTransfer.destination_payment_method_billing_email,
          billingName: outboundTransfer.destination_payment_method_billing_name,
          type: outboundTransfer.destination_payment_method_type,
          bankAccountName:
            outboundTransfer.destination_payment_method_bank_account_name,
          bankAccountHolderType:
            outboundTransfer.destination_payment_method_bank_account_holder_type,
          bankAccountType:
            outboundTransfer.destination_payment_method_bank_account_type,
          bankAccountLast4:
            outboundTransfer.destination_payment_method_bank_account_last4,
          bankAccountNetwork:
            outboundTransfer.destination_payment_method_bank_account_network,
        },
        expectedArrivalDate: outboundTransfer.expected_arrival_date,
        returnedDetailsCode: outboundTransfer.returned_details_code,
        statementDescriptor: outboundTransfer.statement_descriptor,
        statusTransitions: {
          canceledAt: outboundTransfer.status_transitions_canceled_at,
          failedAt: outboundTransfer.status_transitions_failed_at,
          postedAt: outboundTransfer.status_transitions_posted_at,
          returnedAt: outboundTransfer.status_transitions_returned_at,
        },
        trackingDetailsType: outboundTransfer.tracking_details_type,
        typeLabel:
          TypeLabels[FundTransferTypeEnum.OUTBOUND_TRANSFER] ??
          startCase(FundTransferTypeEnum.OUTBOUND_TRANSFER),
        entityType: FundTransferTypeEnum.OUTBOUND_TRANSFER,
      };
      return cleanedData;
    } else if (type === FundTransferTypeEnum.TOP_UP) {
      const topUp = data as TopUpRaw;
      const prettyCreatedAtDate = formatDateToMST(new Date(topUp.created));
      const cleanedData: TopUpClean = {
        id: topUp.id,
        stripeId: topUp.stripe_topup_id,
        amountCents: topUp.amount,
        status: topUp.status,
        createdAt: topUp.created,
        prettyCreatedAtDate,
        description: topUp.description,
        statementDescriptor: topUp.statement_descriptor,
        failureCode: topUp.failure_code,
        failureMessage: topUp.failure_message,
        expectedAvailabilityDate: topUp.expected_availability_date,
        typeLabel:
          TypeLabels[FundTransferTypeEnum.TOP_UP] ??
          startCase(FundTransferTypeEnum.TOP_UP),
        entityType: FundTransferTypeEnum.TOP_UP,
      };
      return cleanedData;
    } else if (type === FundTransferTypeEnum.PAYOUT) {
      const payout = data as PayoutRaw;
      const prettyCreatedAtDate = formatDateToMST(new Date(payout.created));
      const cleanedData: PayoutClean = {
        id: payout.id,
        stripeId: payout.stripe_payout_id,
        amountCents: payout.amount,
        status: payout.status,
        createdAt: payout.created,
        arrivalDate: payout.arrival_date,
        prettyCreatedAtDate,
        description: payout.description,
        statementDescriptor: payout.statement_descriptor,
        failureCode: payout.failure_code,
        failureMessage: payout.failure_message,
        typeLabel:
          TypeLabels[FundTransferTypeEnum.PAYOUT] ??
          startCase(FundTransferTypeEnum.PAYOUT),
        entityType: FundTransferTypeEnum.PAYOUT,
      };
      return cleanedData;
    }
  };

  return useQuery({
    queryKey: queryKeys.balance.fundTransferDetails({
      id,
      type,
    }),
    queryFn: () =>
      getFundTransferDetails({
        id,
        type,
      }),
    enabled: !!isSignedIn && !!privileges?.transactions && !!id,
  });
};
