import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useFetch } from 'src/hooks/useFetch';
import { SERVICE_BENJI_CARD_URLS, queryKeys } from 'src/utils';

interface FundsAssignmentResponse {
  account_id: string;
  amount_cents: number;
  created_at: string;
  created_by_external_id: string | null;
  created_by_internal_user_id: string | null;
  created_by_linked_topup_source_id: string | null;
  group_id: string;
  id: string;
  topup_id: string | null;
}

interface FundsAssignmentArgs {
  groupId: string;
  amountCents: number;
}

export const useAssignFunds = () => {
  const queryClient = useQueryClient();
  const { fetchApi } = useFetch();

  const assignFunds = async ({ amountCents, groupId }: FundsAssignmentArgs) => {
    const { data } = await fetchApi.post<FundsAssignmentResponse>(
      SERVICE_BENJI_CARD_URLS.FUND_ASSIGNMENTS_CREATE,
      {
        amountCents,
        groupId,
      },
    );
    return data;
  };

  return useMutation({
    mutationFn: (data: FundsAssignmentArgs) => assignFunds(data),
    onSuccess: () => {
      queryClient.invalidateQueries([
        ...queryKeys.paginatedData._baseKey,
        ...queryKeys.fundsAssignments._baseKey,
      ]);
      queryClient.invalidateQueries({
        queryKey: queryKeys.balance.current(),
      });
      queryClient.invalidateQueries({
        queryKey: [
          ...queryKeys.paginatedData._baseKey,
          ...queryKeys.groups._baseKey,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [...queryKeys.groups._baseKey],
      });
    },
  });
};
