import { useMutation, useQueryClient } from '@tanstack/react-query';
import { mapValues } from 'lodash';

import { useFetch } from 'src/hooks/useFetch';
import { queryKeys, SERVICE_BENJI_CARD_URLS } from 'src/utils';

import type { UserPrivileges } from 'src/types';

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  const { fetchApi } = useFetch();

  const updateUser = async ({
    userId,
    privileges,
  }: {
    userId: string;
    privileges: UserPrivileges;
  }) => {
    const { data } = await fetchApi.post(
      SERVICE_BENJI_CARD_URLS.USERS_UPDATE_SUB_USER,
      {
        userId,
        // Sometimes the BE returns privileges as null instead of false, this cleans it up before sending it back.
        userPrivileges: mapValues(privileges, (value) => Boolean(value)),
      },
    );

    return data;
  };

  return useMutation({
    mutationFn: (data: { userId: string; privileges: UserPrivileges }) =>
      updateUser(data),
    onSuccess: (_, { userId }) => {
      queryClient.invalidateQueries([
        ...queryKeys.paginatedData._baseKey,
        ...queryKeys.subUsers._baseKey,
      ]);
      queryClient.invalidateQueries(queryKeys.subUsers.details({ id: userId }));
    },
  });
};
