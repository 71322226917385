import { useMutation, useQueryClient } from '@tanstack/react-query';
import { merge } from 'lodash';
import { generatePath } from 'react-router-dom';

import { useFetch } from 'src/hooks/useFetch';
import { SERVICE_BENJI_CARD_URLS, queryKeys } from 'src/utils';

import type { GroupDetailsCleaned, GroupStatus } from 'src/types/groups';

export const useUpdateGroupDetails = () => {
  const queryClient = useQueryClient();
  const { fetchApi } = useFetch();

  const updateGroupDetails = async ({
    details,
    groupId,
  }: {
    details: {
      name?: string;
      status?: GroupStatus;
    };
    groupId: string;
  }) => {
    const { data } = await fetchApi.patch(
      generatePath(SERVICE_BENJI_CARD_URLS.GROUP_UPDATE, {
        groupId,
      }),
      details,
    );
    return data;
  };

  return useMutation({
    mutationFn: ({
      groupId,
      ...details
    }: {
      groupId: string;
      name?: string;
      status?: GroupStatus;
    }) =>
      updateGroupDetails({
        details,
        groupId,
      }),
    onSuccess: (_, { groupId, ...details }) => {
      queryClient.invalidateQueries({
        queryKey: [
          ...queryKeys.paginatedData._baseKey,
          ...queryKeys.groups._baseKey,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          ...queryKeys.paginatedData._baseKey,
          ...queryKeys.cardholders._baseKey,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          ...queryKeys.paginatedData._baseKey,
          ...queryKeys.cards._baseKey,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.cardholders._baseKey,
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.cards._baseKey,
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.balance._baseKey,
      });
      const groupQueryKey = queryKeys.groups.details({
        id: groupId,
      });
      queryClient.setQueryData(
        groupQueryKey,
        (previous?: GroupDetailsCleaned) => {
          if (!previous) return;
          return merge(previous, details);
        },
      );
    },
  });
};
