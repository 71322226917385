import { useUser } from '@clerk/clerk-react';
import { useQuery } from '@tanstack/react-query';

import { useGetUserPrivileges } from 'src/hooks';
import { SERVICE_BENJI_CARD_URLS, queryKeys } from 'src/utils';

import { useFetch } from './useFetch';

interface FundingInstructions {
  fundingInstructions: {
    account_number: string; // '1631263568af'
    bank_name: string; // 'TEST BANK'
    routing_number: string; // '110000000'
  };
}

export const useGetFundingInstructions = () => {
  const { fetchApi } = useFetch();
  const { isSignedIn } = useUser();
  const { privileges } = useGetUserPrivileges();

  const getFundingInstructions = async () => {
    const { data } = await fetchApi.get<FundingInstructions>(
      SERVICE_BENJI_CARD_URLS.ACCOUNTS_GET_FUNDING_INSTRUCTIONS,
    );

    return data;
  };

  return useQuery({
    queryKey: queryKeys.account.fundingInstructions(),
    queryFn: () => getFundingInstructions(),
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !!isSignedIn && !!privileges?.fund_balance,
  });
};
