import { css } from '@emotion/react';
import { Box, Card, Link, Typography } from '@mui/material';
import { isNumber } from 'lodash';

import {
  PaginatedTableWrapper,
  StatusChip,
  Table,
  TableError,
  TableLoader,
  TableNoResults,
} from 'src/components';
import { useFundTransferDetailsModal } from 'src/components/modals/FundTransferDetailsModal';
import { TrackType, useTrack } from 'src/context';
import { useAppRoutes, useGetUserPrivileges } from 'src/hooks';
import { Privilege } from 'src/types/user';
import {
  queryKeys,
  SERVICE_BENJI_CARD_URLS,
  USDFormatterNoCents,
} from 'src/utils';

import { useOverviewCardTableTheme } from './useOverviewCardTableTheme';

import { cleanFundTransfers } from '../FundsTransfers/utils';

import type { StatusType } from 'src/components/StatusChip';
import type { TableColumn } from 'src/components/Table';
import type {
  FundsTransferCleaned,
  FundsTransferRaw,
} from 'src/types/funds-transfers';

export const FundsTransfersOverviewCard = () => {
  const endpointUrl = SERVICE_BENJI_CARD_URLS.FUND_TRANSFERS_GET_ALL;

  const { track } = useTrack();
  const { navigate } = useAppRoutes();
  const { openModal: openBalanceTransferModal } = useFundTransferDetailsModal();

  const { privileges } = useGetUserPrivileges();

  const { tableTheme } = useOverviewCardTableTheme();

  if (!privileges?.balance) {
    return null;
  }

  const columns: ({
    width: string;
  } & TableColumn<FundsTransferCleaned>)[] = [
    {
      label: 'Date',
      renderCell: ({ prettyCreatedAtDate }) => {
        return prettyCreatedAtDate;
      },
      width: '95px',
    },
    {
      label: 'Type',
      renderCell: ({ typeLabel }) => {
        return typeLabel;
      },
      width: 'minmax(140px, 2fr)',
    },
    {
      label: 'Amount',
      renderCell: ({ amountCents }) => {
        return isNumber(amountCents)
          ? USDFormatterNoCents.format(parseInt(`${(amountCents ?? 0) / 100}`))
          : '$---';
      },
      width: '90px',
    },
    {
      label: 'Status',
      renderCell: ({ status, entityType }) => {
        return (
          <Box
            css={css`
              display: flex;
              justify-content: center;
            `}
          >
            <StatusChip status={status} type={entityType as StatusType} />
          </Box>
        );
      },
      width: '100px',
      headerCss: css`
        text-align: center;
      `,
    },
  ];

  return (
    <Card
      css={css`
        flex: 1 1 533px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-bottom: 12px;
        min-height: 260px;
      `}
    >
      <Box
        css={css`
          display: flex;
          justify-content: space-between;
          padding: 12px 14px 0;
        `}
      >
        <Typography variant="h3">Recent Fund Transfers</Typography>
        <Link
          onClick={() => {
            track({
              label: 'Clicked view all link',
              type: TrackType.action,
              actionType: 'click',
              viewAllName: 'Funds Transfers',
            });
            navigate('/secure/funds-management', {
              queryParams: { 'funds-management-tab': '0' },
            });
          }}
          css={css`
            font-weight: 500;
            line-height: normal;
          `}
        >
          View all
        </Link>
      </Box>
      <PaginatedTableWrapper<FundsTransferRaw, FundsTransferCleaned>
        endpointUrl={endpointUrl}
        queryKeyBase={queryKeys.balance._baseKey}
        dataFormatter={cleanFundTransfers}
        requiredPrivileges={[Privilege.balance]}
        shouldUseQueryParamsSearchState={false}
        pageSize={6}
      >
        {({
          queryResult: { isLoading, isError },
          pagination,
          paginationData,
          resultCount,
        }) => (
          <>
            <Table<FundsTransferCleaned>
              data={paginationData}
              pagination={pagination}
              columns={columns}
              isLoading={isLoading}
              onRowClick={({ id, entityType }) => {
                openBalanceTransferModal({ id, type: entityType });
              }}
              columnWidths={columns.map(({ width }) => width).join(' ')}
              theme={tableTheme}
            />
            <TableLoader isLoading={isLoading} />
            <TableError isLoading={isLoading} isError={isError} />
            <TableNoResults
              isLoading={isLoading}
              resultCount={resultCount}
              noResultMessage="No Fund Transfers Yet!"
            />
          </>
        )}
      </PaginatedTableWrapper>
    </Card>
  );
};
