import { css } from '@emotion/react';
import { Link } from '@mui/material';

import { COLOR_PALETTE } from 'src/theme';

import { useGenerateRegulatoryReceiptUrl } from './useGenerateRegulatoryReceiptUrl';

import type { RegulatoryReceiptParams } from './useGenerateRegulatoryReceiptUrl';
export const RegulatoryReceiptButton = ({
  id,
  type,
  label = 'Transaction Receipt',
}: RegulatoryReceiptParams & {
  label?: string;
}) => {
  const { mutate: generateUrl, isLoading } = useGenerateRegulatoryReceiptUrl();

  const handleOnClick = () =>
    generateUrl(
      { id, type },
      {
        onSuccess: ({ regulatoryReceiptUrl }) => {
          window.open(regulatoryReceiptUrl, '_blank');
        },
      },
    );
  return (
    <Link
      component="button"
      onClick={handleOnClick}
      disabled={isLoading}
      css={css`
        color: ${COLOR_PALETTE.blueLink};
      `}
    >
      {label}
    </Link>
  );
};
