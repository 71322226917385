import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useFetch } from 'src/hooks/useFetch';
import { queryKeys, SERVICE_BENJI_CARD_URLS } from 'src/utils';

export const useSetUserStatus = () => {
  const queryClient = useQueryClient();
  const { fetchApi } = useFetch();

  const setUserStatus = async ({
    userId,
    isActive,
  }: {
    userId: string;
    isActive: boolean;
  }) => {
    const { data } = await fetchApi.post(
      isActive
        ? SERVICE_BENJI_CARD_URLS.USERS_ENABLE_SUB_USER
        : SERVICE_BENJI_CARD_URLS.USERS_DISABLE_SUB_USER,
      {
        userId,
      },
    );
    return data;
  };

  return useMutation({
    mutationFn: (data: { userId: string; isActive: boolean }) =>
      setUserStatus(data),
    onSuccess: (_, { userId }) => {
      queryClient.invalidateQueries([
        ...queryKeys.paginatedData._baseKey,
        ...queryKeys.subUsers._baseKey,
      ]);
      queryClient.invalidateQueries(queryKeys.subUsers.details({ id: userId }));
    },
  });
};
