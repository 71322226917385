import { css } from '@emotion/react';
import { Box, IconButton, Link, Typography } from '@mui/material';

import { CancelCloseIcon, ExternalLinkIcon } from 'src/components/Icon';
import { useAppRoutes } from 'src/hooks';
import { COLOR_PALETTE } from 'src/theme';

import BenjiCardPrivacyPolicy from './BenjiCardPrivacyPolicy.pdf';
import BenjiCardTermsOfUse from './BenjiCardTermsOfUse.pdf';

const ExternalLink = ({
  href,
  children,
}: {
  href: string;
  children: string;
}) => (
  <Link
    href={href}
    target="_blank"
    rel="noreferrer"
    css={css`
      display: flex;
      justify-content: space-between;
      gap: 8px;
      align-items: center;
      align-content: center;
      font-size: 14px;
      font-weight: 300;
      text-decoration: none;
      svg {
        height: 16px;
        width: 16px;
      }
    `}
  >
    {children}
    <ExternalLinkIcon />
  </Link>
);

export const LegalPage = () => {
  const { navigate, shouldShowSideNav } = useAppRoutes();
  return (
    <Box
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <Box
        css={css`
          display: flex;
          border-bottom: 1px solid ${COLOR_PALETTE.border};
          padding-bottom: 22px;
        `}
      >
        <Typography
          variant="h1"
          css={css`
            & {
              font-size: 20px;
              font-weight: 700;
            }
          `}
        >
          Legal
        </Typography>
        {!shouldShowSideNav && (
          <IconButton
            onClick={() => navigate('/')}
            color="secondary"
            css={css`
              background-color: ${COLOR_PALETTE.lightButtonColor};
              height: 46px;
              width: 46px;
              margin-left: auto;
            `}
          >
            <CancelCloseIcon
              css={css`
                height: 18px;
                width: 18px;
              `}
            />
          </IconButton>
        )}
      </Box>
      <Box
        css={css`
          max-width: 690px;
          width: 100%;
          margin: 0 auto;
        `}
      >
        <Box
          css={(theme) => css`
            background-color: ${COLOR_PALETTE.darkBackground};
            padding: 17px 20px;
            border-radius: 10px;
            margin-top: 100px;
            ${theme.breakpoints.down('md')} {
              margin-top: 20px;
            }
          `}
        >
          <Typography
            variant="h2"
            css={css`
              & {
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                color: ${COLOR_PALETTE.white};
              }
            `}
          >
            Stripe & Bank Agreements
          </Typography>
        </Box>
        <Box
          css={css`
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 16px;
          `}
        >
          <ExternalLink href="https://stripe.com/legal/connect-account">
            Stripe Connected Account Agreement
          </ExternalLink>
          <ExternalLink href="https://stripe.com/legal/ssa#services-terms">
            Stripe Issuing Accountholder Terms
          </ExternalLink>
          <ExternalLink href="https://stripe.com/legal/celtic-spend-card">
            Issuing Bank Terms (Celtic Bank)
          </ExternalLink>
          <ExternalLink href="https://stripe.com/legal/issuing/celtic/apple-payment-platform-program-manager-customer-terms-and-conditions#exhibit-c-pass-through-provisions">
            Apple Pay Accountholder Terms
          </ExternalLink>
          <ExternalLink href="https://stripe.com/legal/issuing-offer-document">
            Commercial Financing Disclosure (Celtic Bank) (no fee)
          </ExternalLink>
          <ExternalLink href="https://stripe.com/legal/e-sign-disclosure">
            Stripe E-SIGN Disclosure
          </ExternalLink>
          <ExternalLink href="https://stripe.com/legal/issuing/celtic-authorized-user-terms">
            Authorized User Terms (Celtic Bank)
          </ExternalLink>
          <ExternalLink href="https://stripe.com/legal/ssa">
            Stripe Services Agreement
          </ExternalLink>
          <ExternalLink href="https://stripe.com/legal/ssa#services-terms">
            Stripe Treasury Accountholder Terms (Stripe Treasury - Connected
            Accounts)
          </ExternalLink>
        </Box>
        <Box
          css={(theme) => css`
            background-color: ${COLOR_PALETTE.darkBackground};
            padding: 17px 20px;
            border-radius: 10px;
            margin-top: 40px;
            ${theme.breakpoints.down('md')} {
              margin-top: 20px;
            }
          `}
        >
          <Typography
            variant="h2"
            css={css`
              & {
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                color: ${COLOR_PALETTE.white};
              }
            `}
          >
            Benji Card Agreements
          </Typography>
        </Box>
        <Box
          css={css`
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 16px;
          `}
        >
          <ExternalLink href={BenjiCardPrivacyPolicy}>
            Benji Card Privacy Policy
          </ExternalLink>
          <ExternalLink href={BenjiCardTermsOfUse}>
            Benji Card Terms of Use
          </ExternalLink>
        </Box>
      </Box>
    </Box>
  );
};
