import { css } from '@emotion/react';
import {
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Link,
  CircularProgress,
  IconButton,
  Popover,
} from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { omit } from 'lodash';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  DataRow,
  FancyCurrencyDisplay,
  SlideLeftTransition,
  StatusChip,
} from 'src/components';
import {
  TrackContext,
  TrackType,
  TrackingContextProvider,
  useModal,
  useTrack,
} from 'src/context';
import { useAppRoutes, useGetFundTransferDetails } from 'src/hooks';
import { COLOR_PALETTE } from 'src/theme';
import { FundTransferTypeEnum } from 'src/types/funds-transfers';
import { formatDateToMST, searchParamsToObject } from 'src/utils';

import { RegulatoryReceiptButton } from '../RegulatoryReceiptButton';
import { RegulatoryReceiptTypeEnum } from '../RegulatoryReceiptButton/enums';

const BALANCE_TRANSFER_DETAILS_MODAL_ID = 'balance-transfer-details-modal';

export const useFundTransferDetailsModal = (
  props?: Pick<Parameters<typeof useModal>[0], 'onClose' | 'onOpen'>,
) => {
  const { track } = useTrack();
  const { navigate, pathname, getRoutePathName } = useAppRoutes();
  const [searchParams] = useSearchParams();
  const currentSearchParams = searchParamsToObject(searchParams);

  const modal = useModal({
    modalId: BALANCE_TRANSFER_DETAILS_MODAL_ID,
    onClose: () => {
      if (props?.onClose) {
        props.onClose();
      }
      navigate('/secure/funds-management', {
        queryParams: omit(
          currentSearchParams,
          'selectedFundTransferId',
          'selectedFundTransferType',
        ),
        preventScrollReset: true,
      });
    },
    onOpen: () => {
      track({
        label: 'Clicked open modal',
        type: TrackType.action,
        actionType: 'click',
        modalName: 'Funds Transfer Details',
      });
      if (props?.onOpen) {
        props.onOpen();
      }
    },
  });
  return {
    ...modal,
    openModal: ({ id, type }: { id: string; type: FundTransferTypeEnum }) => {
      const isSamePage =
        pathname === getRoutePathName('/secure/funds-management');
      navigate('/secure/funds-management', {
        queryParams: {
          ...(isSamePage && currentSearchParams),
          selectedFundTransferId: id,
          selectedFundTransferType: type,
          'funds-management-tab': '0',
        },
        replace: isSamePage,
        preventScrollReset: isSamePage,
      });
      modal.openModal();
    },
  };
};

const FundTransferDetailsTrackingContextProvider = (props: {
  children: React.ReactNode;
}) => {
  const [searchParams] = useSearchParams();
  const selectedFundTransferId = searchParams.get('selectedFundTransferId');
  const selectedFundTransferType = searchParams.get('selectedFundTransferType');
  const { data: selectedBalanceTransfer } = useGetFundTransferDetails({
    id: selectedFundTransferId ?? '',
    type: selectedFundTransferType as FundTransferTypeEnum,
  });

  return (
    <TrackingContextProvider
      trackingProps={{
        transferId: selectedBalanceTransfer?.id,
        transferAmountCents: selectedBalanceTransfer?.amountCents,
        transferStatus: selectedBalanceTransfer?.status,
        transferCreatedAt: selectedBalanceTransfer?.createdAt,
      }}
      contextName={TrackContext['funds-transfer']}
      {...props}
    />
  );
};
const FundTransferDetailsModalBase = ({
  anchorEl,
}: {
  anchorEl: HTMLElement | null;
}) => {
  const { track } = useTrack();
  const { isOpen, openModal, closeModal } = useFundTransferDetailsModal({
    onClose: () => {
      track({
        label: 'Clicked close modal',
        type: TrackType.action,
        actionType: 'click',
        modalName: 'Funds Transfer Details',
      });
    },
  });
  const { pathname, getRoutePathName } = useAppRoutes();
  const [searchParams] = useSearchParams();
  const selectedFundTransferId = searchParams.get('selectedFundTransferId');
  const selectedFundTransferType = searchParams.get('selectedFundTransferType');
  const { data: selectedFundTransfer, isLoading } = useGetFundTransferDetails({
    id: selectedFundTransferId ?? '',
    type: selectedFundTransferType as FundTransferTypeEnum,
  });
  useEffect(() => {
    if (!isOpen && selectedFundTransferId) {
      openModal({
        id: selectedFundTransferId,
        type: selectedFundTransferType as FundTransferTypeEnum,
      });
    }
  }, [isOpen, openModal, selectedFundTransferId, selectedFundTransferType]);

  const isSamePage = pathname === getRoutePathName('/secure/funds-management');
  useEffect(() => {
    if (
      isOpen &&
      (!selectedFundTransferId || !selectedFundTransferType) &&
      isSamePage
    ) {
      closeModal();
    }
  }, [
    closeModal,
    isOpen,
    selectedFundTransferId,
    isSamePage,
    selectedFundTransferType,
  ]);

  const handleCloseModal = () => {
    closeModal();
  };

  const createdAtDateObj =
    !!selectedFundTransfer?.createdAt &&
    new Date(selectedFundTransfer.createdAt);

  return (
    <Popover
      open={isOpen}
      onClose={handleCloseModal}
      anchorEl={anchorEl}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'center',
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      css={(theme) => css`
        ${theme.breakpoints.down('sm')} {
          top: 14px;
        }

        z-index: 1300;

        .MuiPopover-paper {
          display: flex;
          flex-direction: column;
          max-height: 680px;
          height: 100%;
          padding: 0;
          margin: 0;
          max-width: 500px;
          width: 100%;
          border-radius: 14px;
          border: 1px solid ${COLOR_PALETTE.modalBorder};
          box-shadow: 0px 7px 21.8px 15px ${COLOR_PALETTE.modalShadow};
          @media (max-width: 524px) {
            max-width: calc(100vw - 24px);
          }
          @media (max-height: 710px) {
            max-height: calc(100vh - 70px);
            ${theme.breakpoints.down('sm')} {
              max-height: calc(100vh - 80px);
            }
          }
        }
      `}
      TransitionComponent={SlideLeftTransition}
    >
      <DialogTitle
        component="div"
        css={css`
          position: relative;
          display: flex;
          flex-wrap: nowrap;
          gap: 0 8px;
          align-items: center;
          padding: 18px 16px;
          min-height: 58px;
          padding-left: 44px;
          background-color: ${COLOR_PALETTE.modalHeaderBackground};
        `}
      >
        <IconButton
          aria-label="close"
          onClick={closeModal}
          css={css`
            color: ${COLOR_PALETTE.modalHeaderText};
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          `}
        >
          <GridCloseIcon />
        </IconButton>
        <Typography
          variant="h3"
          css={css`
            flex: 1 1 200px;
            color: ${COLOR_PALETTE.modalHeaderText};
            font-weight: 700;
            line-height: normal;
          `}
        >
          Funds Transfer Details
        </Typography>
      </DialogTitle>
      <DialogContent
        css={css`
          display: flex;
          flex-direction: column;
          padding: 0;
        `}
      >
        {isLoading ? (
          <Box
            css={css`
              flex: 1 1 100%;
              display: flex;
              min-height: 200px;
              justify-content: center;
              align-items: center;
              padding: 24px;
            `}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            css={css`
              padding: 10px 8px;
            `}
          >
            {selectedFundTransfer?.entityType ===
            FundTransferTypeEnum.RECEIVED_CREDIT ? (
              <>
                <DataRow
                  label="Status"
                  value={
                    <StatusChip
                      status={selectedFundTransfer.status ?? ''}
                      type={selectedFundTransfer.entityType}
                    />
                  }
                />
                <DataRow
                  label="Date"
                  value={
                    !!createdAtDateObj && formatDateToMST(createdAtDateObj)
                  }
                />
                <DataRow
                  label="Amount"
                  value={
                    <FancyCurrencyDisplay
                      amountCents={selectedFundTransfer.amountCents}
                    />
                  }
                />
                <DataRow label="Type" value={selectedFundTransfer.typeLabel} />
              </>
            ) : selectedFundTransfer?.entityType ===
              FundTransferTypeEnum.RECEIVED_DEBIT ? (
              <>
                <DataRow
                  label="Status"
                  value={
                    <StatusChip
                      status={selectedFundTransfer.status ?? ''}
                      type={selectedFundTransfer.entityType}
                    />
                  }
                />
                <DataRow
                  label="Date"
                  value={
                    !!createdAtDateObj && formatDateToMST(createdAtDateObj)
                  }
                />
                <DataRow
                  label="Amount"
                  value={
                    <FancyCurrencyDisplay
                      amountCents={selectedFundTransfer.amountCents}
                    />
                  }
                />
                <DataRow label="Type" value={selectedFundTransfer.typeLabel} />
              </>
            ) : selectedFundTransfer?.entityType ===
              FundTransferTypeEnum.OUTBOUND_TRANSFER ? (
              <>
                <DataRow
                  label="Status"
                  value={
                    <StatusChip
                      status={selectedFundTransfer.status ?? ''}
                      type={selectedFundTransfer.entityType}
                    />
                  }
                />
                <DataRow
                  label="Date"
                  value={
                    !!createdAtDateObj && formatDateToMST(createdAtDateObj)
                  }
                />
                <DataRow
                  label="Amount"
                  value={
                    <FancyCurrencyDisplay
                      amountCents={selectedFundTransfer.amountCents}
                    />
                  }
                />
                <DataRow label="Type" value={selectedFundTransfer.typeLabel} />
              </>
            ) : selectedFundTransfer?.entityType ===
              FundTransferTypeEnum.PAYOUT ? (
              <>
                <DataRow
                  label="Status"
                  value={
                    <StatusChip
                      status={selectedFundTransfer.status ?? ''}
                      type={selectedFundTransfer.entityType}
                    />
                  }
                />
                <DataRow
                  label="Date"
                  value={
                    !!createdAtDateObj && formatDateToMST(createdAtDateObj)
                  }
                />
                <DataRow
                  label="Amount"
                  value={
                    <FancyCurrencyDisplay
                      amountCents={selectedFundTransfer.amountCents}
                    />
                  }
                />
                <DataRow label="Type" value={selectedFundTransfer.typeLabel} />
              </>
            ) : selectedFundTransfer?.entityType ===
              FundTransferTypeEnum.TOP_UP ? (
              <>
                <DataRow
                  label="Status"
                  value={
                    <StatusChip
                      status={selectedFundTransfer.status ?? ''}
                      type={selectedFundTransfer.entityType}
                    />
                  }
                />
                <DataRow
                  label="Date"
                  value={
                    !!createdAtDateObj && formatDateToMST(createdAtDateObj)
                  }
                />
                <DataRow
                  label="Amount"
                  value={
                    <FancyCurrencyDisplay
                      amountCents={selectedFundTransfer.amountCents}
                    />
                  }
                />
                <DataRow label="Type" value={selectedFundTransfer.typeLabel} />
              </>
            ) : null}
          </Box>
        )}
        <Box
          css={css`
            margin-top: auto;
          `}
        >
          {!!selectedFundTransfer &&
            (Object.values(RegulatoryReceiptTypeEnum) as string[]).includes(
              selectedFundTransfer.entityType,
            ) && (
              <Typography
                variant="body2"
                css={css`
                  width: 100%;
                  text-align: center;
                  font-size: 12px;
                  font-weight: 300;
                  color: ${COLOR_PALETTE.lightTextOnLight};
                `}
              >
                View{' '}
                <RegulatoryReceiptButton
                  id={selectedFundTransfer.id}
                  type={
                    selectedFundTransfer.entityType as string as RegulatoryReceiptTypeEnum
                  }
                />
              </Typography>
            )}
          <Typography
            variant="body1"
            css={css`
              width: 100%;
              padding: 8px 16px 16px;
              text-align: center;
              font-size: 12px;
              font-weight: 300;
              line-height: 166.66%;
              color: ${COLOR_PALETTE.lightTextOnLight};
            `}
          >
            If you have any issues with this funds transfer, please contact us
            at{' '}
            <Link
              href={`mailto:support@benjicard.com?subject=Issue with funds transfer (${selectedFundTransfer?.stripeId})`}
              css={css`
                color: ${COLOR_PALETTE.blueLink};
              `}
            >
              support@benjicard.com
            </Link>
            .
          </Typography>
        </Box>
      </DialogContent>
    </Popover>
  );
};

export const FundTransferDetailsModal = ({
  anchorEl,
}: {
  anchorEl: HTMLElement | null;
}) => {
  return (
    <FundTransferDetailsTrackingContextProvider>
      <FundTransferDetailsModalBase anchorEl={anchorEl} />
    </FundTransferDetailsTrackingContextProvider>
  );
};
