import { useAuth } from '@clerk/clerk-react';

export const useFetch = (props?: { generateSecureHeaders: boolean }) => {
  const { generateSecureHeaders = true } = props || {};
  const { getToken } = useAuth();

  const generateSecureHeadersFunction = async () => {
    const token = await getToken();
    return {
      Authorization: `Bearer ${token}`,
    };
  };

  const fetchApi = {
    async request<T>(
      url: string,
      method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' = 'GET',
      body?: unknown,
      additionalHeaders?: HeadersInit,
    ): Promise<{ data: T; headers: Headers }> {
      const secureHeaders = generateSecureHeaders
        ? await generateSecureHeadersFunction()
        : {};

      const headers: HeadersInit = {
        'Content-Type': 'application/json',
        ...secureHeaders,
        ...additionalHeaders,
      };

      const response = await fetch(url, {
        method,
        headers,
        body: body ? JSON.stringify(body) : undefined,
      });

      if (!response.ok) {
        throw new Error(
          `API Request Failed: ${response.status} ${response.statusText}`,
        );
      }

      if (response.status === 204) {
        return { data: null as unknown as T, headers: response.headers };
      }

      const contentType = response.headers.get('Content-Type') || '';

      let responseData: T;
      if (contentType.includes('application/json')) {
        responseData = (await response.json()) as T;
      } else {
        responseData = (await response.text()) as unknown as T;
      }

      return {
        data: responseData,
        headers: response.headers,
      };
    },

    get<T>(url: string, additionalHeaders?: HeadersInit) {
      return fetchApi.request<T>(url, 'GET', undefined, additionalHeaders);
    },

    post<T>(url: string, body: unknown, additionalHeaders?: HeadersInit) {
      return fetchApi.request<T>(url, 'POST', body, additionalHeaders);
    },

    put<T>(url: string, body: unknown, additionalHeaders?: HeadersInit) {
      return fetchApi.request<T>(url, 'PUT', body, additionalHeaders);
    },

    patch<T>(url: string, body: unknown, additionalHeaders?: HeadersInit) {
      return fetchApi.request<T>(url, 'PATCH', body, additionalHeaders);
    },

    delete<T>(url: string, additionalHeaders?: HeadersInit) {
      return fetchApi.request<T>(url, 'DELETE', undefined, additionalHeaders);
    },
  };

  return { fetchApi };
};
