import { css } from '@emotion/react';
import { Box, Link, Typography } from '@mui/material';

import { useAppRoutes } from 'src/hooks';
import { COLOR_PALETTE } from 'src/theme';

export const PageFooter = ({ className }: { className?: string }) => {
  const { navigate, shouldShowContactUsFooterLink } = useAppRoutes();

  return (
    <Box
      className={className}
      css={css`
        padding: 16px;
      `}
    >
      <Typography
        variant="body2"
        component="div"
        css={(theme) => css`
          display: flex;
          flex-wrap: wrap;
          gap: 0 18px;
          flex: 0 1 auto;
          text-align: center;
          font-weight: 300;
          justify-content: center;
          border-top: 1px solid ${COLOR_PALETTE.border};
          padding-top: 16px;

          &,
          a {
            font-size: 12px;
            line-height: 20px;
          }

          a {
            cursor: pointer;
            color: ${theme.palette.text.primary};
            text-decoration: underline;
            font-weight: 400;
            &:hover {
              color: ${theme.palette.text.primary};
            }
          }
        `}
      >
        <Box
          css={css`
            flex: 0 0 auto;
            display: flex;
            gap: 0 18px;
          `}
        >
          <Link
            variant="body2"
            onClick={() => navigate('/legal')}
            color="inherit"
            css={css`
              white-space: nowrap;
            `}
          >
            Legal
          </Link>
          {shouldShowContactUsFooterLink && (
            <Link
              variant="body2"
              onClick={() => navigate('/contact-us')}
              color="inherit"
              css={css`
                white-space: nowrap;
              `}
            >
              Contact Us
            </Link>
          )}
        </Box>
      </Typography>
    </Box>
  );
};
