import { useMutation } from '@tanstack/react-query';

import { SERVICE_BENJI_CARD_URLS } from 'src/utils';

import { useFetch } from '../../hooks/useFetch';

import type { RegulatoryReceiptTypeEnum } from './enums';

interface Response {
  regulatoryReceiptUrl: string;
}

export interface RegulatoryReceiptParams {
  id: string;
  type: RegulatoryReceiptTypeEnum;
}

export const useGenerateRegulatoryReceiptUrl = () => {
  const { fetchApi } = useFetch();

  const createCard = async ({ id, type }: RegulatoryReceiptParams) => {
    const { data } = await fetchApi.post<Response>(
      SERVICE_BENJI_CARD_URLS.GENERATE_STRIPE_REGULATORY_RECEIPT,
      {
        id,
        type,
      },
    );
    return data;
  };

  return useMutation({
    mutationFn: (data: RegulatoryReceiptParams) => createCard(data),
  });
};
