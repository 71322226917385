import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useFetch } from 'src/hooks/useFetch';
import { SERVICE_BENJI_CARD_URLS, queryKeys } from 'src/utils';

import type { FormFields } from './NewGroup';
import type { GroupRaw } from 'src/types/groups';

export const useCreateGroup = () => {
  const queryClient = useQueryClient();
  const { fetchApi } = useFetch();

  const createGroup = async ({ name, status }: FormFields) => {
    const { data } = await fetchApi.post<GroupRaw>(
      SERVICE_BENJI_CARD_URLS.GROUP_CREATE,
      {
        name,
        status,
      },
    );
    return data;
  };

  return useMutation({
    mutationFn: (data: FormFields) => createGroup(data),
    onSuccess: () => {
      queryClient.invalidateQueries([
        ...queryKeys.paginatedData._baseKey,
        ...queryKeys.groups._baseKey,
      ]);
    },
  });
};
