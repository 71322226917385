import { css } from '@emotion/react';
import { Chip } from '@mui/material';
import { startCase, get } from 'lodash';

import { GroupFundsAssignmentStatus } from 'src/types/funds-assignments';
import {
  FundTransferTypeEnum,
  OutboundTransferStatus,
  PayoutStatusEnum,
  RecievedCreditStatus,
  RecievedDebitStatus,
  TopupStatusEnum,
} from 'src/types/funds-transfers';
import { UserStatus } from 'src/types/user';

export type StatusType =
  | 'card'
  | 'cardholder'
  | 'dispute'
  | 'transaction'
  | 'user'
  | 'group'
  | FundTransferTypeEnum.RECEIVED_CREDIT
  | FundTransferTypeEnum.RECEIVED_DEBIT
  | FundTransferTypeEnum.OUTBOUND_TRANSFER
  | FundTransferTypeEnum.TOP_UP
  | FundTransferTypeEnum.PAYOUT
  | 'fundAssignment';

const statusLabelOverrides: Partial<
  Record<StatusType, Record<string, string>>
> = {
  transaction: {
    dispute_won: 'Dispute Credit',
  },
  user: {
    disabled: 'Inactive',
    [UserStatus.PENDING_CLERK_INVITATION]: 'Pending',
  },
  [FundTransferTypeEnum.RECEIVED_CREDIT]: {
    [RecievedCreditStatus.FAILED]: 'Failed',
    [RecievedCreditStatus.SUCCEEDED]: 'Completed',
  },
  [FundTransferTypeEnum.RECEIVED_DEBIT]: {
    [RecievedDebitStatus.FAILED]: 'Failed',
    [RecievedDebitStatus.SUCCEEDED]: 'Completed',
  },
  [FundTransferTypeEnum.OUTBOUND_TRANSFER]: {
    [OutboundTransferStatus.CANCELED]: 'Canceled',
    [OutboundTransferStatus.FAILED]: 'Failed',
    [OutboundTransferStatus.POSTED]: 'Completed',
    [OutboundTransferStatus.PROCESSING]: 'Processing',
    [OutboundTransferStatus.RETURNED]: 'Returned',
  },
  [FundTransferTypeEnum.TOP_UP]: {
    [TopupStatusEnum.REVERSED]: 'Reversed',
    [TopupStatusEnum.SUCCEEDED]: 'Completed',
    [TopupStatusEnum.PENDING]: 'Pending',
    [TopupStatusEnum.CANCELED]: 'Canceled',
    [TopupStatusEnum.FAILED]: 'Failed',
  },
  [FundTransferTypeEnum.PAYOUT]: {
    [PayoutStatusEnum.PAID]: 'Completed',
    [PayoutStatusEnum.IN_TRANSIT]: 'Pending',
    [PayoutStatusEnum.PENDING]: 'Pending',
    [PayoutStatusEnum.CANCELED]: 'Canceled',
    [PayoutStatusEnum.FAILED]: 'Failed',
  },
  fundAssignment: {
    [GroupFundsAssignmentStatus.INTERNAL_FAILURE]: 'Failed',
    [GroupFundsAssignmentStatus.PENDING]: 'Processing',
    [GroupFundsAssignmentStatus.COMPLETED]: 'Completed',
    [GroupFundsAssignmentStatus.FAILED]: 'Failed',
  },
};
const getStatusLabel = ({
  type,
  status,
}: {
  type: StatusType;
  status: string;
}) => get(statusLabelOverrides, [type, status], startCase(status));

export const StatusChip = ({
  status,
  type,
  className,
}: {
  status: string;
  type: StatusType;
  className?: string;
}) => {
  if (type === FundTransferTypeEnum.RECEIVED_CREDIT) {
    return (
      <Chip
        className={className}
        label={getStatusLabel({
          status: status,
          type,
        })}
        variant="outlined"
        size="small"
        css={css`
          ${([RecievedCreditStatus.SUCCEEDED] as string[]).includes(
            status ?? '',
          )
            ? css`
                color: #50c034;
                border-color: #50c034;
              `
            : css`
                color: #888888;
                border-color: #888888;
              `}
        `}
      />
    );
  } else if (type === FundTransferTypeEnum.RECEIVED_DEBIT) {
    return (
      <Chip
        className={className}
        label={getStatusLabel({
          status: status,
          type,
        })}
        variant="outlined"
        size="small"
        css={css`
          ${([RecievedDebitStatus.SUCCEEDED] as string[]).includes(status ?? '')
            ? css`
                color: #50c034;
                border-color: #50c034;
              `
            : css`
                color: #888888;
                border-color: #888888;
              `}
        `}
      />
    );
  } else if (type === FundTransferTypeEnum.OUTBOUND_TRANSFER) {
    return (
      <Chip
        className={className}
        label={getStatusLabel({
          status: status,
          type,
        })}
        variant="outlined"
        size="small"
        css={css`
          ${([OutboundTransferStatus.POSTED] as string[]).includes(status ?? '')
            ? css`
                color: #50c034;
                border-color: #50c034;
              `
            : css`
                color: #888888;
                border-color: #888888;
              `}
        `}
      />
    );
  } else if (type === FundTransferTypeEnum.TOP_UP) {
    return (
      <Chip
        className={className}
        label={getStatusLabel({
          status: status,
          type,
        })}
        variant="outlined"
        size="small"
        css={css`
          ${['succeeded', 'paid'].includes(status ?? '')
            ? css`
                color: #50c034;
                border-color: #50c034;
              `
            : css`
                color: #888888;
                border-color: #888888;
              `}
        `}
      />
    );
  } else if (type === FundTransferTypeEnum.PAYOUT) {
    return (
      <Chip
        className={className}
        label={getStatusLabel({
          status: status,
          type,
        })}
        variant="outlined"
        size="small"
        css={css`
          ${['succeeded', 'paid'].includes(status ?? '')
            ? css`
                color: #50c034;
                border-color: #50c034;
              `
            : css`
                color: #888888;
                border-color: #888888;
              `}
        `}
      />
    );
  } else if (type === 'card') {
    return (
      <Chip
        className={className}
        label={getStatusLabel({ status, type })}
        variant="outlined"
        size="small"
        css={css`
          ${status === 'active'
            ? css`
                color: #50c034;
                border-color: #50c034;
              `
            : status === 'canceled'
            ? css`
                color: #fd5045;
                border-color: #fd5045;
              `
            : css`
                color: #888888;
                border-color: #888888;
              `}
        `}
      />
    );
  } else if (type === 'cardholder') {
    return (
      <Chip
        className={className}
        label={getStatusLabel({ status, type })}
        variant="outlined"
        size="small"
        css={css`
          ${status === 'active'
            ? css`
                color: #50c034;
                border-color: #50c034;
              `
            : css`
                color: #888888;
                border-color: #888888;
              `}
        `}
      />
    );
  } else if (type === 'dispute') {
    return (
      <Chip
        className={className}
        label={getStatusLabel({ status, type })}
        variant="outlined"
        size="small"
        css={css`
          ${status === 'expired'
            ? css`
                color: #02a0fc;
                border-color: #02a0fc;
              `
            : status === 'disputed' || status === 'lost'
            ? css`
                color: #fd5045;
                border-color: #fd5045;
              `
            : status === 'submitted'
            ? css`
                color: #9747ff;
                border-color: #9747ff;
              `
            : status === 'won'
            ? css`
                color: #50c034;
                border-color: #50c034;
              `
            : css`
                color: #888888;
                border-color: #888888;
              `}
        `}
      />
    );
  } else if (type === 'transaction') {
    return (
      <Chip
        className={className}
        label={getStatusLabel({ status, type })}
        variant="outlined"
        size="small"
        css={css`
          ${status === 'approved'
            ? css`
                color: #50c034;
                border-color: #50c034;
              `
            : status === 'declined'
            ? css`
                color: #fd5045;
                border-color: #fd5045;
              `
            : status === 'reversed'
            ? css`
                color: #fd5045;
                border-color: #fd5045;
              `
            : status === 'disputed'
            ? css`
                color: #02a0fc;
                border-color: #02a0fc;
              `
            : status === 'refunded' || status === 'dispute_won'
            ? css`
                color: #4339f2;
                border-color: #4339f2;
              `
            : status === 'pending'
            ? css`
                color: #b0b0b0;
                border-color: #b0b0b0;
              `
            : css`
                color: #888888;
                border-color: #888888;
              `}
        `}
      />
    );
  } else if (type === 'user') {
    return (
      <Chip
        className={className}
        label={getStatusLabel({ status, type })}
        variant="outlined"
        size="small"
        css={css`
          ${status === 'active'
            ? css`
                color: #50c034;
                border-color: #50c034;
              `
            : status === 'disabled'
            ? css`
                color: #fd5045;
                border-color: #fd5045;
              `
            : css`
                color: #888888;
                border-color: #888888;
              `}
        `}
      />
    );
  } else if (type === 'group') {
    return (
      <Chip
        className={className}
        label={getStatusLabel({ status, type })}
        variant="outlined"
        size="small"
        css={css`
          ${status === 'active'
            ? css`
                color: #50c034;
                border-color: #50c034;
              `
            : status === 'inactive'
            ? css`
                color: #fd5045;
                border-color: #fd5045;
              `
            : css`
                color: #888888;
                border-color: #888888;
              `}
        `}
      />
    );
  } else if (type === 'fundAssignment') {
    return (
      <Chip
        className={className}
        label={getStatusLabel({
          status: status,
          type,
        })}
        variant="outlined"
        size="small"
        css={css`
          ${(
            [
              GroupFundsAssignmentStatus.INTERNAL_FAILURE,
              GroupFundsAssignmentStatus.FAILED,
            ] as string[]
          ).includes(status ?? '')
            ? css`
                color: #fd5045;
                border-color: #fd5045;
              `
            : status === GroupFundsAssignmentStatus.COMPLETED
            ? css`
                color: #50c034;
                border-color: #50c034;
              `
            : css`
                color: #888888;
                border-color: #888888;
              `}
        `}
      />
    );
  } else return null;
};
