import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useFetch } from 'src/hooks/useFetch';
import { CardholderType } from 'src/types/cardholders';
import { SERVICE_BENJI_CARD_URLS, queryKeys } from 'src/utils';

import type { FormFields } from './NewCardholder/CreateCardholderForm';
import type { Address } from 'src/types';

export enum CreateCardholderErrorCode {
  DUPLICATE_CARDHOLDER = 'DUPLICATE_CARDHOLDER',
}

interface MutationArgs extends Omit<FormFields, 'dob'> {
  dob?: string;
  skipExistingCardHolder?: boolean;
}

interface CreateCardholderResponse {
  id: string;
  stripe_cardholder_id: string;
  billing: {
    address: Address;
  };
  company: string | null;
  created: string;
  email: string;
  name: string;
  metadata: {
    type: string;
  };
  phone_number: string;
  spending_controls: null;
  requirements: {
    past_due: [];
    disabled_reason: string;
  };
  status: string;
}

export const useCreateCardholder = () => {
  const queryClient = useQueryClient();
  const { fetchApi } = useFetch();

  const createCardholder = async ({
    name,
    address,
    phone,
    email,
    dob,
    skipExistingCardHolder,
    groupId,
  }: MutationArgs) => {
    const { data } = await fetchApi.post<CreateCardholderResponse>(
      SERVICE_BENJI_CARD_URLS.CARDHOLDER_CREATE,
      {
        skipExistingCardHolder,
        newCardHolder: {
          first_name: name.first,
          last_name: name.last,
          // Both types still exist on the BE but we are phasing it out, hardcoding to MEMBER on the FE for now.
          type: CardholderType.MEMBER,
          phone_number: phone,
          email,
          dob,
        },
        billingInfo: {
          street: address.line1,
          street2: address.line2,
          city: address.city,
          state: address.state,
          postal_code: address.postalCode,
          country: address.country,
        },
        agreedToTermsAt: new Date().toISOString(),
        groupId,
      },
    );
    return data;
  };

  return useMutation({
    mutationFn: (data: MutationArgs) => createCardholder(data),
    onSuccess: () => {
      queryClient.invalidateQueries([
        ...queryKeys.paginatedData._baseKey,
        ...queryKeys.cardholders._baseKey,
      ]);
    },
  });
};
