import { useUser } from '@clerk/clerk-react';
import { useQuery } from '@tanstack/react-query';

import { SERVICE_BENJI_CARD_URLS, queryKeys } from 'src/utils';

import { useFetch } from './useFetch';
import { useGetUserPrivileges } from './useGetUserPrivileges';

interface AccountOverview {
  accountBalance: {
    currentCents: number;
    availableCents: number;
    unallocatedAvailableCents: number;
    allocatedAvailableCents: number;
    unassignedAvailableCents: number;
    assignedAvailableCents: number;
  };
  cardsWithRemainingSpendLimit: number;
  staleCards: number;
  countOfOverAllocatedGroups: number | null;
}

export const useGetAccountOverview = () => {
  const { fetchApi } = useFetch();
  const { isSignedIn } = useUser();
  const { privileges } = useGetUserPrivileges();

  const getAccountOverview = async () => {
    const { data } = await fetchApi.get<AccountOverview>(
      SERVICE_BENJI_CARD_URLS.ACCOUNT_GET_OVERVIEW,
    );

    return data;
  };

  return useQuery({
    queryKey: queryKeys.balance.current(),
    queryFn: () => getAccountOverview(),
    enabled: !!isSignedIn && !!privileges?.balance,
  });
};
