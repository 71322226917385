import { useUser } from '@clerk/clerk-react';
import { H } from 'highlight.run';
import { cloneDeepWith } from 'lodash';
import { useEffect } from 'react';

import { useGetUserDetails, useGetUserPrivileges } from 'src/hooks';

const networkBodyKeysToRedact = new Set([
  'street',
  'street1',
  'street2',
  'line1',
  'line2',
  'firstName',
  'lastName',
  'first_name',
  'last_name',
  'memberFirstName',
  'memberLastName',
  'member_first_name',
  'member_last_name',
  'cardholder_name',
  'cardholderName',
  'serviceDate',
  'service_date',
  'name',
  'phone_number',
  'phoneNumber',
  'dob',
  'cardholderDob',
  'memberBirthDate',
  'member_birth_date',
  'ephemeralKeySecret',
  'ephemeral_key_secret',
]);

const redactSensitiveData = (data: unknown): unknown => {
  return cloneDeepWith(data, (_, key) => {
    if (typeof key === 'string' && networkBodyKeysToRedact.has(key)) {
      return '__REDACTED__';
    }
  });
};

H.init(process.env.REACT_APP_HIGHLIGHT_PROJECT_ID, {
  environment: process.env.REACT_APP_ENV,
  serviceName: 'frontend-app',
  tracingOrigins: true,
  disableSessionRecording: window.location.hostname === 'localhost',
  enablePerformanceRecording: true,
  networkRecording: {
    enabled: true,
    recordHeadersAndBody: true,
    urlBlocklist: [
      '/api/accounts/fundingInstructions',
      'clerk.accounts.dev',
      'clerk.benjicard.com',
      'stripe.com',
    ],
    requestResponseSanitizer: (pair) => {
      // Redact sensitive data in request & response body if they exist
      if (pair.request.body && typeof pair.request.body === 'object') {
        pair.request.body = redactSensitiveData(pair.request.body);
      }

      if (pair.response.body && typeof pair.response.body === 'object') {
        pair.response.body = redactSensitiveData(pair.response.body);
      }
      return pair;
    },
  },
});

export const Highlight = () => {
  const { isSignedIn } = useUser();
  const { data: user } = useGetUserDetails();
  const { privileges, isSuccess: hasLoadedPrivileges } = useGetUserPrivileges();
  const { data: userDetails, isSuccess: hasLoadedUserDetails } =
    useGetUserDetails();

  useEffect(() => {
    if (isSignedIn && user && hasLoadedPrivileges && hasLoadedUserDetails) {
      const name = `${userDetails.userDetails.first_name} ${userDetails.userDetails.last_name}`;
      H.identify(user.userDetails.id, {
        email: user.userDetails.email,
        highlightDisplayName: name,
        name,
        company: userDetails.accountDetails.companyName,
        ...(!!privileges && privileges),
      });
    }
  }, [
    hasLoadedPrivileges,
    hasLoadedUserDetails,
    isSignedIn,
    privileges,
    user,
    userDetails?.accountDetails.companyName,
    userDetails?.userDetails.first_name,
    userDetails?.userDetails.last_name,
  ]);

  return null;
};
