import { useUser } from '@clerk/clerk-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useFetch } from 'src/hooks/useFetch';
import { SERVICE_BENJI_CARD_URLS, queryKeys } from 'src/utils';

import type { Address } from 'src/types';

export const useUpdateCompanyInfo = () => {
  const { user } = useUser();
  const queryClient = useQueryClient();
  const { fetchApi } = useFetch();

  const updateCompanyInfo = async ({
    companyName,
    companyAddress,
    companySupportEmail,
  }: {
    companyName: string;
    companyAddress: Address;
    companySupportEmail: string;
  }) => {
    const { data } = await fetchApi.post<{
      company_name: string;
      support_email: string;
      address: {
        city: string;
        country: string;
        line1: string;
        line2: string;
        postal_code: string;
        state: string;
      };
    }>(SERVICE_BENJI_CARD_URLS.COMPANY_INFO_UPDATE, {
      companyName,
      companyAddress,
      supportEmail: companySupportEmail,
    });
    return data;
  };

  return useMutation({
    mutationFn: (data: {
      companySupportEmail: string;
      companyName: string;
      companyAddress: Address;
    }) => updateCompanyInfo(data),
    onSuccess: () => {
      const userDetailsQueryKey = queryKeys.account.details({
        userId: user?.externalId ?? '',
      });
      queryClient.invalidateQueries(userDetailsQueryKey);
    },
  });
};
