import type { GroupStatus } from './groups';

export enum GroupFundsAssignmentStatus {
  INTERNAL_FAILURE = 'internal_failure',
  PENDING = 'pending',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum GroupFundsAssignmentType {
  TO_GROUP = 'TO_GROUP',
  FROM_GROUP = 'FROM_GROUP',
  GROUP_TO_GROUP = 'GROUP_TO_GROUP',
}

export interface FundsAssignmentRaw {
  account_id: string;
  amount_cents: number;
  created_at: string;
  created_by_external_id: string | null;
  created_by_internal_user_id: string | null;
  user?: {
    id: string;
    first_name: string;
    last_name: string;
  };
  created_by_linked_topup_source_id: string | null;
  group: {
    id: string;
    name: string;
  };
  id: string;
  status: GroupFundsAssignmentStatus;
  type: GroupFundsAssignmentType;
}

export interface FundsAssignmentCleaned {
  accountId: FundsAssignmentRaw['account_id'];
  amountCents: FundsAssignmentRaw['amount_cents'];
  createdAt: FundsAssignmentRaw['created_at'];
  prettyCreatedAtDate: string;
  isCreatedAtSameAsPrev: boolean;

  createdByExternalId: FundsAssignmentRaw['created_by_external_id'];
  createdByInternalUserId: FundsAssignmentRaw['created_by_internal_user_id'];
  createdByInternalUserName: string;
  createdByLinkedTopupSourceId: FundsAssignmentRaw['created_by_linked_topup_source_id'];
  group: FundsAssignmentRaw['group'];
  id: FundsAssignmentRaw['id'];
  status: FundsAssignmentRaw['status'];
  type: FundsAssignmentRaw['type'];
}

export interface FundsAssignmentDetailsRaw {
  id: string;
  account_id: string;
  group_id: string;
  created_at: string;
  created_by_internal_user_id: string | null;
  created_by_external_id: string | null;
  amount_cents: number;
  status: GroupFundsAssignmentStatus;
  type: GroupFundsAssignmentType;
  group: {
    id: string;
    name: string;
    account_id: string;
    status: GroupStatus;
    created_at: string;
    created_by_external: string | null;
    created_by_internal: string | null;
    balance_current_cents: number;
    balance_available_cents: number;
    balance_unallocated_cents: number;
    balance_allocated_cents: number;
    balance_allocated_percent: number;
  };
  user?: {
    id: string;
    first_name: string;
    last_name: string;
  };
  outbound_transfers: {
    id: string;
  }[];
  received_credits: {
    id: string;
  }[];
}

export interface FundsAssignmentDetailsCleaned {
  id: FundsAssignmentDetailsRaw['id'];
  createdAt: FundsAssignmentDetailsRaw['created_at'];
  prettyCreatedAtDate: string;
  createdByInternalUserId: FundsAssignmentDetailsRaw['created_by_internal_user_id'];
  createdByInternalUserName?: string | null;
  createdByExternalId: FundsAssignmentDetailsRaw['created_by_external_id'];
  amountCents: FundsAssignmentDetailsRaw['amount_cents'];
  status: FundsAssignmentDetailsRaw['status'];
  type: FundsAssignmentDetailsRaw['type'];
  group: {
    name: string;
    id: string;
  };
  relatedOutboundTransfers: {
    id: string;
  }[];
  relatedReceivedCredits: {
    id: string;
  }[];
}
