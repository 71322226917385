import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useFetch } from 'src/hooks/useFetch';
import { queryKeys, SERVICE_BENJI_CARD_URLS } from 'src/utils';

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  const { fetchApi } = useFetch();

  const deleteUser = async ({ userId }: { userId: string }) => {
    const { data } = await fetchApi.post(
      SERVICE_BENJI_CARD_URLS.USERS_DELETE_SUB_USER,
      {
        userId,
      },
    );
    return data;
  };

  return useMutation({
    mutationFn: (data: { userId: string }) => deleteUser(data),
    onSuccess: () => {
      queryClient.invalidateQueries([
        ...queryKeys.paginatedData._baseKey,
        ...queryKeys.subUsers._baseKey,
      ]);
    },
  });
};
