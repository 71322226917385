import { useUser } from '@clerk/clerk-react';
import { useQuery } from '@tanstack/react-query';
import { pickBy } from 'lodash';

import { SERVICE_BENJI_CARD_URLS, queryKeys } from 'src/utils';

import { useAppRoutes } from './useAppRoutes';
import { useFetch } from './useFetch';

import type { UserPrivileges } from 'src/types';

export const useGetUserPrivileges = () => {
  const { isSignedIn } = useUser();
  const { isAuthenticatedRoute } = useAppRoutes();
  const { fetchApi } = useFetch();

  const getUserPrivileges = async () => {
    const { data } = await fetchApi.get<UserPrivileges>(
      SERVICE_BENJI_CARD_URLS.USER_GET_PRIVILEGES,
    );
    return data;
  };

  const { data, ...rest } = useQuery<UserPrivileges>({
    queryFn: () => getUserPrivileges(),
    queryKey: queryKeys.account.privileges(),
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !!isSignedIn && !!isAuthenticatedRoute,
  });
  return {
    privileges: pickBy(
      data,
      (value) => typeof value === 'boolean',
    ) as UserPrivileges,
    ...rest,
  };
};
